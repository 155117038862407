import React from 'react';
import { Alert, Spinner } from 'react-bootstrap';

import logos from '../../../img/stripe/logos-stripe-connect.svg';
import useStripeConnectionStatus from '../../../hooks/use-stripe-connection-status.ts';

import './PaymentSetup.scss';
import ConnectStripeBtn from './ConnectStripeBtn';
import BusinessUrlForm from '../../Payments/BusinessUrlForm';
import ResponsiveImage from '../../ResponsiveImage/ResponsiveImage.tsx';

function PaymentSetUp() {
  const { isLoading, canMakePayments, accountCompleted } =
    useStripeConnectionStatus();

  return (
    <section className="w-100 mt-3 mt-md-0 container-fluid payment-setup__wrapper">
      {accountCompleted && !canMakePayments && (
        <div className="row gx-0">
          <div className="col">
            <Alert
              variant="warning"
              className="small d-flex flex-column flex-xl-row align-items-start align-items-xl-center gap-3 justify-content-xl-between"
            >
              <span>
                You need to add a business URL in order to accept/make payments
                using a credit card.{' '}
                <Alert.Link
                  target="_blank"
                  referrerPolicy="no-referrer"
                  href="https://support.stripe.com/questions/business-website-for-account-activation-faq"
                >
                  Learn more
                </Alert.Link>
              </span>
              <BusinessUrlForm redirectTo="/settings/payment-setup" />
            </Alert>
          </div>
        </div>
      )}
      <div
        className="row shadow-sm bg-white rounded gx-0"
        style={{ minHeight: 600 }}
      >
        <div className="col-xl-5">
          <div
            className="border-1 p-3 p-md-5 rounded-start-2 p-4"
            style={{
              backgroundColor: 'rgba(99, 91, 255, 0.07)',
              height: 'min(100%, 600px)',
            }}
          >
            {isLoading ? (
              <div className="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
                <Spinner style={{ alignSelf: 'auto' }} variant="secondary" />
              </div>
            ) : (
              <div className="d-flex flex-column gap-3 payment-setup__load-fade">
                <h1
                  className="fs-xl-3 fs-4 lh-base"
                  style={{ color: 'rgba(99, 91, 255)' }}
                >
                  {!accountCompleted
                    ? 'Connect your LeadsBox account with Stripe'
                    : 'Update your Stripe account information'}
                </h1>

                <ConnectStripeBtn accountCompleted={accountCompleted} />

                <p>
                  Enhance your experience by creating a stripe account linked to
                  your LeadsBox user.
                </p>
                <ul className="payment-setup__list text-muted">
                  <li>Accept credit card payments</li>
                  <li>Send payment links</li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="col-xl-7">
          {isLoading ? (
            <div className="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
              <Spinner style={{ alignSelf: 'auto' }} variant="secondary" />
            </div>
          ) : (
            <div className=" payment-setup__hero">
              <ResponsiveImage
                containerClass="payment-setup__background"
                imageName="fondo-stripe"
                folder="stripe"
                alt="background image"
                ratioX={1}
                ratioY={1}
                oneX={1080}
                oneAndHalfX={1620}
                twoX={2160}
              />
              <img
                className="payment-setup__logos"
                alt="stripe and leadsbox logos connected by two arrows"
                src={logos}
              />
            </div>
          )}
        </div>
      </div>
    </section>
    // <div className="d-flex justify-content-center">
    //   <a
    //     className="btn btn-primary p-2 mb-3 me-3 "
    //     href={url}
    //     target="_blank"
    //     rel="noreferrer"
    //   >
    //     <div className="d-flex justify-content-center">
    //       <img
    //         alt="stripe"
    //         src={stripe}
    //         style={{ height: '90px', margin: '0, auto' }}
    //       />
    //     </div>

    //     <p className="gateway-setup-text">Gateway Setup</p>
    //   </a>
    // </div>
  );
}

export default PaymentSetUp;

import { handleResposeAbstraction } from '../../API/fetchUtils';
import paymentsAPI from '../../API/routesAPI/paymentsAPI';
import settingsAPI from '../../API/routesAPI/settings';

export const stripePayment = async () => {
  return null;
  // ! WARNING This will need to be removed after BE update
  /* eslint-disable */
  const { paymentId, paymentToken } = params;
  const response = await paymentsAPI.creditCard(paymentId, paymentToken);
  return handleResposeAbstraction({ response });
};

export const generateStripeAccount = async ({ params }) => {
  return null;
  // ! WARNING This will need to be removed after BE update
  // eslint-disable */
  const { stripeToken } = params;
  const response = await settingsAPI.stripeLinkSuccess(stripeToken);
  return handleResposeAbstraction({ response });
};

import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { PAYMENT_OPTIONS } from '../../../Payments/CreatePayment';
import { I_Data } from '../propTypes';

const s = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 9,
    fontWeight: 300,
    gap: 16,
    width: '100%',
    paddingVertical: 12,
    alignItems: 'baseline',
  },
  paymentInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  checkbox: {
    height: 8,
    width: 8,
    border: '1px solid gray',
    borderRadius: 3,
  },
  checkLine: {
    width: 80,
    borderBottom: '1px solid gray',
    alignSelf: 'flex-end',
  },
});

function PaymentInfo({ data }: { data: I_Data }) {
  const paymentMethodLabel = PAYMENT_OPTIONS.find(
    (opt) => opt.value === data.paymentMethod
  )?.label;

  return (
    <View style={s.container}>
      <Text style={{ fontWeight: 600 }}>Payment Method</Text>
      <View style={s.paymentInfoRow}>
        <Text>
          {paymentMethodLabel}{' '}
          {data.paymentMethod === 3
            ? `nº ${data?.checkNumber || 'Missing Nº'}`
            : null}
        </Text>
      </View>
      <Text style={{ fontWeight: 600 }}>Invoice Nº:</Text>
      <View style={s.paymentInfoRow}>
        <Text>{data?.invoiceNumber || 'Missing invoice number'}</Text>
      </View>
    </View>
  );
}

export default PaymentInfo;

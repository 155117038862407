/* eslint react/prop-types: 0 */
import React from 'react';
import { Form } from 'react-bootstrap';
import { addDays, parse } from 'date-fns';
// import Select from 'react-select';
import SelectWithDefault from '../Forms/Select/SelectWithDefault';

function InvoiceDatesForm({
  errors,
  values,
  handleBlur,
  touched,
  setFieldValue,
  handleChange,
  // invoiceId,
  // copy,
}) {
  const optionsTerms = [
    { value: 'due', label: 'Due on receipt' },
    { value: 7, label: 'Net 7 days' },
    { value: 15, label: 'Net 15 days' },
    { value: 30, label: 'Net 30 days' },
    { value: 'custom', label: 'Custom terms' },
  ];

  return (
    <div className="container-fluid gx-0">
      <h5 className="mb-4 mt-4">Invoice information</h5>
      <div className="row mb-3">
        <div className="col-lg-6">
          <Form.Group className="mb-3">
            <Form.Label className="small">
              Invoice terms days
              <span className="text-danger">*</span>
            </Form.Label>
            <SelectWithDefault
              name="invoice_terms"
              options={optionsTerms}
              value={values.invoice_terms}
              isInvalid={touched.invoice_terms && !!errors.invoice_terms}
              handleChange={async (e) => {
                const nextValue = e?.value ? e.value : '';
                await setFieldValue(`invoice_terms`, `${nextValue}`);
                if (
                  nextValue !== values.invoice_terms &&
                  nextValue !== 'custom'
                ) {
                  const date = new Date(values.invoice_date_created);
                  date.setDate(date.getDate() + nextValue);
                  const nextDate = date.toISOString().substring(0, 10);
                  await setFieldValue('invoice_date_due', nextDate);
                }
              }}
              setFieldValue={setFieldValue}
              requiered
            />

            <Form.Control.Feedback type="invalid">
              {errors.invoice_terms}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="col-lg-3">
          <Form.Group className="mb-3">
            <Form.Label className="small">
              Date created <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              as="input"
              name="invoice_date_created"
              id="invoice_date_created"
              type="date"
              isInvalid={
                touched.invoice_date_created && !!errors.invoice_date_created
              }
              isValid={
                touched.invoice_date_created && !errors.invoice_date_created
              }
              value={values.invoice_date_created}
              onChange={async (e) => {
                // Stale the values
                const newValue = e.target.value;
                const invoiceTerms = values.invoice_terms;

                // If due use same day for due date
                if (invoiceTerms === 'due') {
                  await setFieldValue('invoice_date_due', newValue);
                }

                // For numeric offsets, convert value to Number and add
                if (invoiceTerms !== 'due' && invoiceTerms !== 'custom') {
                  const parsedDate = parse(newValue, 'yyyy-MM-dd', new Date());
                  const endDate = addDays(parsedDate, Number(invoiceTerms))
                    .toISOString()
                    .substring(0, 10);

                  await setFieldValue('invoice_date_due', endDate);
                }

                // Always update the field itself
                await setFieldValue('invoice_date_created', newValue);
              }}
              onBlur={handleBlur}
            />
            <Form.Control.Feedback type="invalid">
              {errors.invoice_date_created}
            </Form.Control.Feedback>
          </Form.Group>
        </div>

        <div className="col-lg-3">
          <Form.Group className="mb-3">
            <Form.Label className="small">
              Date end<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              as="input"
              name="invoice_date_due"
              id="invoice_date_due"
              type="date"
              isInvalid={touched.invoice_date_due && !!errors.invoice_date_due}
              isValid={touched.invoice_date_due && !errors.invoice_date_due}
              value={values.invoice_date_due}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              disabled={values.invoice_terms !== 'custom'}
            />
            <Form.Control.Feedback type="invalid">
              {errors.invoice_date_due}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="col-6">
          <Form.Group className="mb-3">
            <Form.Label className="small">Invoice number</Form.Label>
            <Form.Control
              as="input"
              name="invoice_number"
              isInvalid={touched.invoice_number && !!errors.invoice_number}
              isValid={touched.invoice_number && !errors.invoice_number}
              value={values.invoice_number}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              disabled
              plaintext
            />
            <Form.Control.Feedback type="invalid">
              {errors.invoice_number}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        {/* {(!invoiceId || copy) && (
          <div className="col-6">
            <Form.Group className="mb-3">
              <Form.Label className="small">Deposit</Form.Label>
              <Form.Control
                as="input"
                name="invoice_deposit"
                isInvalid={touched.invoice_deposit && !!errors.invoice_deposit}
                isValid={touched.invoice_deposit && !errors.invoice_deposit}
                value={values.invoice_deposit}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="0.00"
              />
              <Form.Control.Feedback type="invalid">
                {errors.invoice_deposit}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        )} */}
      </div>
    </div>
  );
}

export default InvoiceDatesForm;

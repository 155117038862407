import {
  FormikErrors,
  FormikHandlers,
  FormikTouched,
  FormikValues,
} from 'formik';
import React from 'react';
import LBInputGroup from '../LBInputGroup/LBInputGroup';

// element = { element };
// values = { values };
// touched = { touched };
// errors = { errors };
// handleChange = { handleChange };
// handleBlur = { handleBlur };

interface PhoneInputProps {
  element: any; // 🤡 To be able to specify this we need to redo whole CreateForm in TS
  values: FormikValues;
  touched: FormikTouched<FormikValues>;
  errors: FormikErrors<FormikValues>;
  handleChange: FormikHandlers;
  handleBlur: FormikHandlers;
}

function PhoneInput({
  element,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
}: PhoneInputProps) {
  const nextElement = {
    ...element,
    config: {
      leftLabel: '+1',
    },
  };

  return (
    <LBInputGroup
      element={nextElement}
      values={values}
      touched={touched}
      errors={errors}
      handleChange={handleChange}
      handleBlur={handleBlur}
    />
  );
}

export default PhoneInput;

import React from 'react';
import { Link, useRouteError } from 'react-router-dom';

import logo from '../../img/logo/logo_invoice_manager.svg';

function ErrorPage() {
  const error = useRouteError();
  return (
    <main
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: 'var(--bs-gray-200)',
        gap: 8,
      }}
    >
      <div
        className="border border-secondary rounded text-muted"
        style={{
          aspectRatio: '8.5 / 11',
          height: 'auto',
          width: 'min(90vw, 640px)',
          display: 'grid',
          placeContent: 'center',
        }}
      >
        {error?.message ||
          'Unknow error. Try again later or contact the site administrator.'}
      </div>

      <footer className="mt-3 d-flex gap-3 align-items-center justify-content-center">
        <span className="text-muted small">Powered by:</span>
        <Link to="/">
          <img style={{ height: 40 }} src={logo} alt="leadsbox logo" />
        </Link>
      </footer>
    </main>
  );
}

export default ErrorPage;

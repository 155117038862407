import { useEffect, useState } from 'react';
import { useFetcher, useParams } from 'react-router-dom';
import formatCurrency from '../../Dashboard/Widgets/utils/formatCurrency';

export default function getSelectData(
  loaderURL,
  combo = false,
  hasAll = false
) {
  const fetcher = useFetcher();
  const { customerId } = useParams();
  const [options, setOptions] = useState([]);

  const productsCategories = () => {
    const categories = fetcher?.data?.categories;
    const nextOptions =
      categories &&
      categories?.map((item) => ({
        value: item.family_id,
        label: `${item.family_name}`,
      }));
    setOptions(nextOptions);
  };

  const comboProductsCategories = () => {
    const categories = fetcher?.data?.categories;
    const nextOptions =
      categories &&
      categories?.map((item) => ({
        value: item.family_id,
        label: `${item.family_name} (${
          item.total_products ? item.total_products : 'No available'
        } items)`,
        isDisabled: item.total_products === 0,
      }));
    setOptions(nextOptions);
  };

  const expensesCategories = () => {
    const expenseCategories = fetcher?.data?.result;
    const CategoriesOptions =
      expenseCategories &&
      expenseCategories.map((item) => ({
        value: item.expense_category_id,
        label: item.expense_category_name,
      }));
    setOptions(CategoriesOptions);
  };

  const invoices = () => {
    let invoicesData;

    if (customerId) {
      invoicesData = fetcher?.data?.invoices
        .filter((invoice) => invoice.client_id === Number(customerId))
        .sort((a, b) => b.invoice_id - a.invoice_id);
    } else {
      invoicesData = fetcher?.data?.invoices;
    }
    const InvoicesOptions =
      invoicesData &&
      invoicesData.map((item) => ({
        value: item.invoice_id,
        label: `${item.invoice_number} |  ${item.client_name} | Balance:
          ${formatCurrency(item.invoice_balance, 2)}`,
        number: item.invoice_number,
        clientName: item.client_name,
        balance: formatCurrency(item.invoice_balance, 2),
      }));

    if (invoicesData && hasAll) {
      setOptions([{ value: 'all', label: 'All' }, ...InvoicesOptions]);
    } else {
      setOptions(InvoicesOptions);
    }
  };

  const customers = () => {
    const customersData = fetcher?.data?.customers;
    if (customersData) {
      const CustomersOptions = customersData.map((customer) => ({
        value: customer.id,
        label: customer.name,
        address: customer.address.full_address,
        phone: customer.phone,
        email: customer.email,
        name: customer.name,
      }));
      setOptions(CustomersOptions);
    }
  };

  const vehicles = () => {
    const vehiclesData = fetcher?.data?.vehicles;
    if (vehiclesData) {
      const vehiclesOptions = vehiclesData.map(
        ({ id, brand, model, productionYear, licensePlate }) => ({
          label: `${brand} ${model} ${productionYear}`,
          brand,
          model,
          productionYear,
          licensePlate,
          value: id,
        })
      );
      setOptions(vehiclesOptions);
    }
  };

  const trades = () => {
    const tradesData = fetcher?.data?.data;
    if (tradesData) {
      const groupedOptions = [];
      Object.keys(tradesData).forEach((group) => {
        const formattedGroup = tradesData[group].map((item) => ({
          value: item.id,
          label: item.name,
        }));
        groupedOptions.push({
          label: group.charAt(0).toUpperCase() + group.slice(1),
          options: formattedGroup,
        });
        // console.log(groupedOptions);
        setOptions(groupedOptions);
      });
    }
  };

  const projectManagers = () => {
    const projectManagersData = fetcher?.data?.project_managers;
    if (projectManagersData) {
      const nextOptions = projectManagersData?.map((pm) => ({
        value: pm.id,
        label: pm.name,
      }));
      setOptions(nextOptions);
    }
  };

  useEffect(() => {
    if (fetcher.state === 'idle' && !fetcher.data) {
      fetcher.load(loaderURL);
    }
    switch (loaderURL) {
      case '/products/categories':
        if (combo) comboProductsCategories();
        else productsCategories();
        break;
      case '/expenses/categories':
        expensesCategories();
        break;
      case '/invoices':
        invoices();
        break;
      case '/customers':
        customers();
        break;
      case '/settings/trades':
        trades();
        break;
      case '/settings/assets':
        vehicles();
        break;
      case '/invoices/project-managers':
        projectManagers();
        break;
      default:
        break;
    }
  }, [fetcher]);
  return options;
}

import React from 'react';
import {
  Link,
  useNavigate,
  useNavigation,
  useSearchParams,
} from 'react-router-dom';
import { Alert, Button, Spinner } from 'react-bootstrap';

// app.leadsboxinvoice.com/#/process-payment?status=success&redirect=false&error=&payment_id=pi_3R863LI6dwgBFadJ02bzauZp

function StripePaymentSuccess() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { state } = useNavigation();
  const status = searchParams.get('status');
  const error = searchParams.get('error');
  const paymentId = searchParams.get('payment_id');
  const token = searchParams.get('token');
  const redirect = searchParams.get('redirect');

  const [timeLeft, setTimeLeft] = React.useState(8);

  React.useEffect(() => {
    if (status !== 'success' || redirect === 'false') return undefined;

    if (timeLeft <= 0 && status === 'success') {
      navigate(`/payments/${paymentId}`);
      return undefined;
    }

    const timer = window.setTimeout(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => window.clearTimeout(timer);
  }, [timeLeft, navigate, paymentId]);

  if (error || status !== 'success') {
    // ? Not sure what kind of shape the error has
    // eslint-disable-next-line no-console
    console.error(error || 'Unknown error');
    return (
      <>
        <Alert className="mt-3" variant="danger">
          {error || 'Unknown error'}
        </Alert>
        <p className="mt-3">
          There was an errror processing your payment. You can try again or
          contact the site administrator.
        </p>
        <Button className="mt-3" variant="primary" as={Link} to="/">
          Go to dashboard
        </Button>
        <hr />
        <small className="mt-3 text-muted d-block">
          Note that you need to be logged in to view any payment. If you are not
          the recipient of this payment, you can ignore this window.
        </small>
      </>
    );
  }

  return (
    <>
      <Alert variant="success" className="mt-3">
        Success! Payment received.
      </Alert>
      <p className="mt-3">
        {redirect !== 'false'
          ? "We'll attempt to redirect you to the resulting payment now. If you are not redirected, please click on the button below."
          : 'You can access your payment receipt by clicking the link below.'}
      </p>
      {redirect !== 'false' && (
        <div className="mt-3 fw-semibold">
          {state !== 'idle' ? (
            <Spinner size="sm" />
          ) : (
            <span>Redirecting in... {timeLeft || ''}</span>
          )}
        </div>
      )}

      <Button
        className="mt-3"
        variant="primary"
        as={Link}
        to={
          redirect !== 'false'
            ? `/payments/${paymentId}`
            : `/preview-pdf/payment?token=${token}`
        }
      >
        Go to payment
      </Button>
      {redirect !== 'false' && (
        <>
          <hr />
          <small className="mt-3 text-muted d-block">
            Note that you need to be logged in to view any payment. If you are
            not the recipient of this payment, you can ignore this window.
          </small>
        </>
      )}
    </>
  );
}

export default StripePaymentSuccess;

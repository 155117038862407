import React, { useEffect, useState } from 'react';
import proptypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import { useFetcher } from 'react-router-dom';
import getSelectData from './getSelectData';
import { getSelectedValue } from './findSelection';

const createElement = {
  productCategory: {
    placeholder: 'Select or create',
    action: '/products/categories/new-utility',
    actionKey: 'family_name',
    loader: '/products/categories',
  },
  expenseCategory: {
    placeholder: 'Select or create',
    action: '/expenses/categories/new-utility',
    actionKey: 'expense_category_name',
    loader: '/expenses/categories',
  },
  trades: {
    placeholder: 'Select or create',
    action: '/settings/trades',
    actionKey: 'name',
    loader: '/settings/trades',
  },
  projectManagers: {
    placeholder: 'Select or create',
    action: '/invoices/project-managers',
    actionKey: 'name',
    loader: '/invoices/project-managers',
  },
};

function CreateSelect({ createTarget, handleChange, isInvalid, name, value }) {
  const fetcher = useFetcher();
  const [label, setLabel] = useState('');
  const { placeholder, action, actionKey, loader } =
    createElement[createTarget];

  const options = getSelectData(loader);

  useEffect(() => {
    if (fetcher?.data?.id) {
      handleChange({ label, value: fetcher.data.id }, 'set-value');
    }
  }, [fetcher]);

  const isLoading = fetcher?.state !== 'idle';

  const handleCreate = (inputValue) => {
    setLabel(inputValue);

    const formData = new FormData();
    formData.append(`${actionKey}`, inputValue);
    fetcher.submit(formData, {
      method: 'post',
      action,
    });
  };

  const selectedValue = getSelectedValue(options, value);
  return (
    <CreatableSelect
      isClearable
      isDisabled={isLoading}
      isLoading={isLoading}
      placeholder={placeholder}
      className={isInvalid ? 'invalid_form is-invalid' : ''}
      onChange={(e, selectAction) => {
        handleChange(e, selectAction);
      }}
      onCreateOption={handleCreate}
      options={options}
      defaultValue={{ value: '', label: placeholder }}
      value={selectedValue}
      aria-label={name}
      id={name}
    />
  );
}

CreateSelect.propTypes = {
  value: proptypes.oneOfType([proptypes.string, proptypes.number]),
  handleChange: proptypes.func.isRequired,
  isInvalid: proptypes.bool,
  name: proptypes.string.isRequired,
  createTarget: proptypes.string.isRequired,
};

export default CreateSelect;

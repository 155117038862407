/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import React from 'react';
import { createPortal } from 'react-dom';
import { useFetcher } from 'react-router-dom';
import { Form, Spinner } from 'react-bootstrap';
import LBOffCanvas from '../../UI/LBOffCanvas';
import IconButton from '../../UI/IconButton/IconButton.tsx';
import { Add, Checkmark, Edit, TrashCan } from '../../UI/Icons/Icons';
import LBFormControl from '../../Forms/CreateForm/LBFormControl';
import useProjectManagerForm from './use-pm-form';

function ListItem({ id, label, selectedId, toggleId }) {
  const itemFetcher = useFetcher();
  const isSelected = id === selectedId;
  const [optimisticUpdate, setOptimisticUpdate] = React.useState('');

  const submitCallback = (values) => {
    setOptimisticUpdate(values.name);
    toggleId('');
  };

  const { formik, isLoading, field } = useProjectManagerForm({
    submitCallback,
    initialValue: label,
    id,
    endpoint: `/invoices/project-managers/${id}/update`,
  });

  if (isSelected) {
    return (
      <itemFetcher.Form
        id={`pm-form-${id}`}
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
        noValidate
        className="p-2 rounded d-flex gap-2"
        style={{ backgroundColor: 'var(--bs-gray-100)' }}
      >
        <LBFormControl
          element={field}
          values={formik.values}
          touched={formik.touched}
          errors={formik.errors}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
        />
        <IconButton
          type="submit"
          color="success"
          outline={false}
          className="w-100"
          disabled={isLoading}
        >
          <Checkmark />
        </IconButton>
      </itemFetcher.Form>
    );
  }

  return (
    <div
      className="d-flex border align-items-center p-2 px-3 rounded gap-2"
      style={{ borderColor: 'var(--bs-gray-100)' }}
    >
      <span className="flex-grow-1 text-truncate">
        {optimisticUpdate || label || 'Loading...'}{' '}
        {isLoading && <Spinner size="sm" color="secondary" />}
      </span>
      <div className="d-flex gap-2 flex-shrink-0">
        <IconButton
          className="flex-shrink-0"
          color="primary"
          onClick={() => toggleId(id)}
          disabled={isLoading || itemFetcher.state !== 'idle'}
        >
          <Edit />
        </IconButton>
        <itemFetcher.Form
          className="flex-shrink-0"
          method="DELETE"
          action={`/invoices/project-managers/${id}/delete`}
          onSubmit={(e) => {
            e.stopPropagation();
          }}
        >
          <IconButton
            color="danger"
            type="submit"
            disabled={isLoading || itemFetcher.state !== 'idle'}
          >
            <TrashCan />
          </IconButton>
        </itemFetcher.Form>
      </div>
    </div>
  );
}

function ProjectManagers() {
  const [showModal, setShowModal] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState('');
  const toggleId = (id) => {
    setSelectedId(id);
  };

  const fetcher = useFetcher();

  React.useEffect(() => {
    if (!fetcher.data && fetcher.state === 'idle') {
      fetcher.load('/invoices/project-managers');
    }
  }, [fetcher]);

  const projectManagers =
    fetcher?.data?.project_managers.sort((a, b) => a.id - b.id) || [];

  const { formik, isLoading, field } = useProjectManagerForm({
    initialValue: '',
    endpoint: `/invoices/project-managers`,
  });

  return (
    <>
      <button
        style={{
          appearance: 'none',
          border: 'none',
          background: 'none',
          color: 'var(--bs-primary)',
          padding: 0,
        }}
        className="btn btn-link btn-sm"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Manage
      </button>
      <LBOffCanvas
        show={showModal}
        handleCloseModal={() => setShowModal(false)}
      >
        <LBOffCanvas.Wrapper>
          <LBOffCanvas.Left>
            <LBOffCanvas.Header>Project Managers</LBOffCanvas.Header>
            {fetcher.state !== 'idle' && <Spinner />}
            {fetcher.state === 'idle' && projectManagers?.length <= 0 && (
              <span>No project managers</span>
            )}
            {projectManagers?.length > 0 && fetcher.state === 'idle' && (
              <div className="d-flex flex-column gap-3">
                {projectManagers &&
                  projectManagers.map((item) => (
                    <ListItem
                      key={item.id}
                      label={item.name}
                      id={item.id}
                      selectedId={selectedId}
                      toggleId={toggleId}
                    />
                  ))}
              </div>
            )}
          </LBOffCanvas.Left>
        </LBOffCanvas.Wrapper>
      </LBOffCanvas>
      {document.querySelector('#offcanvas-action-group-left')
        ? createPortal(
            <fetcher.Form
              id="pm-form-new"
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit(e);
              }}
              noValidate
              className="p-2 rounded d-flex align-items-stretch gap-2 w-100"
            >
              <Form.Group
                className="w-100"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="small">
                  Add new Project Manager
                </Form.Label>
                <div className="d-flex gap-3 align-items-start">
                  <div className="w-100">
                    <LBFormControl
                      id="test"
                      element={field}
                      values={formik.values}
                      touched={formik.touched}
                      errors={formik.errors}
                      handleChange={formik.handleChange}
                      handleBlur={formik.handleBlur}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors[field.name]}
                    </Form.Control.Feedback>
                  </div>
                  <IconButton
                    type="submit"
                    color="success"
                    outline={false}
                    className="w-100"
                    disabled={isLoading}
                  >
                    <Add />
                  </IconButton>
                </div>
              </Form.Group>
            </fetcher.Form>,
            document.querySelector('#offcanvas-action-group-left')
          )
        : null}
    </>
  );
}

export default ProjectManagers;

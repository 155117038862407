/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */

import React from 'react';

import { CHART_COLORS } from './testData';
import OneThird from './PieCharts/OneThird';
import WidgetContainer, {
  EmptyWidget,
} from './WidgetContainer/WidgetContainer';
import useWidgetFilter from './utils/useWidgetFilter';
import OneThirdPlaceholder from './PieCharts/OneThirdPlaceholder';

// This is the data we would get from the DB, not sure if
// we already have a saved value for "Net profit", probably not,
// and we need to calculate it
// const TEST_DATA = [
//   {
//     name: 'Income',
//     value: 200,
//   },
//   {
//     name: 'Expenses',
//     value: 500,
//   },
// ];

function ProfitAndLoss() {
  const {
    serverMessage,
    fetcherData,
    fetcherState,
    filter,
    handleSelectChange,
  } = useWidgetFilter('profit-and-loss');

  const { data, total } = React.useMemo(() => {
    if (!fetcherData) return { data: null, total: null };

    const { value: expenses } = fetcherData.find(
      (entry) => entry.name === 'Expense'
    );
    const { value: income } = fetcherData.find(
      (entry) => entry.name === 'Income'
    );
    const netProfit = income - expenses;
    const incomePlusExpenses = income + expenses;

    const profitLossData = [
      ...fetcherData,
      {
        name: 'Net Profit',
        value: netProfit,
      },
    ].map((dataPoint, index) => {
      return {
        ...dataPoint,
        color: CHART_COLORS[index],
      };
    });

    return { data: profitLossData, total: incomePlusExpenses };
  }, [fetcherData]);

  return (
    <WidgetContainer>
      <WidgetContainer.Header
        title="Profit & Loss"
        cta="Go to reports"
        url="/reports/business-overview"
      >
        <WidgetContainer.Select
          value={filter}
          onChangeHandler={handleSelectChange}
        >
          <option value="thisMonth">This Month</option>
          <option value="thisYear">This Year</option>
          <option value="lastYear">Last Year</option>
        </WidgetContainer.Select>
      </WidgetContainer.Header>
      <WidgetContainer.Body
        fetcherState={fetcherState}
        entries={fetcherData?.length}
        loadingComponent={<OneThirdPlaceholder />}
        serverMessage={serverMessage}
      >
        {total <= 0 ? (
          <EmptyWidget />
        ) : (
          <div
            className="d-flex flex-column flex-sm-row align-items-center justify-items-center"
            style={{ width: '100%', height: '100%' }}
          >
            {data.map(({ name, value, color }) => (
              <OneThird
                key={`${name}-${value}`}
                valueName={name}
                partValue={value}
                wholeValue={total}
                fillColor={color}
              />
            ))}
          </div>
        )}
      </WidgetContainer.Body>
    </WidgetContainer>
  );
}

export default ProfitAndLoss;

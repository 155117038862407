/* eslint-disable react/jsx-props-no-spreading */
import { HTMLAttributes, useEffect, useState } from 'react';
import './ResponsiveImage.scss';

type ResponsiveImageProps = {
  imageName: string;
  folder: string;
  alt?: string;
  ratioX?: number;
  ratioY?: number;
  oneX: string;
  oneAndHalfX: string;
  twoX: string;
  containerClass?: string;
} & HTMLAttributes<HTMLImageElement>;

// Helper function to dynamically import images
const getImgUrl = async (fileName: string, format: string, folder: string) => {
  try {
    const response = await import(`../../img/${folder}/${fileName}.${format}`);
    return response.default;
  } catch (err) {
    console.error(`Image not found: ${fileName}.${format}`, err);
    return '';
  }
};

function Placeholder({ ...delegated }): JSX.Element {
  return (
    <div className={delegated?.className}>
      <div {...delegated} className="placeholder" />
    </div>
  );
}

function ResponsiveImage({
  imageName,
  folder,
  alt = 'Consider adding a backup text.',
  ratioX = 16,
  ratioY = 9,
  oneX,
  oneAndHalfX,
  twoX,
  containerClass,
  ...delegated
}: ResponsiveImageProps) {
  const [imageUrls, setImageUrls] = useState<{
    avif: string[];
    webp: string[];
    jpg: string[];
  }>({
    avif: ['', '', ''],
    webp: ['', '', ''],
    jpg: ['', '', ''],
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function loadImages() {
      const sizes = [oneX, oneAndHalfX, twoX];

      const avif = await Promise.all(
        sizes.map((size) => getImgUrl(`${imageName}-${size}`, 'avif', folder))
      );
      const webp = await Promise.all(
        sizes.map((size) => getImgUrl(`${imageName}-${size}`, 'webp', folder))
      );
      const jpg = await Promise.all(
        sizes.map((size) => getImgUrl(`${imageName}-${size}`, 'jpg', folder))
      );

      setImageUrls({ avif, webp, jpg });
      setIsLoading(false);
    }

    loadImages();
  }, [imageName, folder, oneX, oneAndHalfX, twoX]);

  return (
    <div>
      {!isLoading && (
        <picture className={containerClass || ''}>
          <source
            srcSet={`${imageUrls.avif[0]} 1x, ${imageUrls.avif[1]} 1.5x, ${imageUrls.avif[2]} 2x`}
            type="image/avif"
          />
          <source
            srcSet={`${imageUrls.webp[0]} 1x, ${imageUrls.webp[1]} 1.5x, ${imageUrls.webp[2]} 2x`}
            type="image/webp"
          />
          <source
            srcSet={`${imageUrls.jpg[0]} 1x, ${imageUrls.jpg[1]} 1.5x, ${imageUrls.jpg[2]} 2x`}
            type="image/jpeg"
          />

          <img
            {...delegated}
            className={isLoading ? 'd-none' : ''}
            src={imageUrls.jpg[0]}
            alt={alt}
            onLoad={() => setIsLoading(false)}
          />
        </picture>
      )}

      {isLoading && (
        <Placeholder
          className="placeholder"
          style={{ '--w': ratioX, '--h': ratioY }}
        />
      )}
    </div>
  );
}

export default ResponsiveImage;

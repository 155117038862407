import { useFetcher } from 'react-router-dom';
import { I_UserSettings } from './helpers/propTypes';
import { deleteNullKeys } from '../../../utils/typeScriptUtils';
import { getFromData } from './helpers/fields';
import GenericForm from '../../Forms/builders/GenericForm';
import useFetcherOnce from '../../Invoices/useFetcherOnce';

export default function MandatoryFieldsForm(): JSX.Element {
  // HOOKS
  const fetcher = useFetcher();
  const { data } = useFetcherOnce('/settings/accountInfo');

  // VARIABLES DEFINITIONS
  const settingsData = data as I_UserSettings;
  const fields = getFromData(settingsData);

  // LOGIC
  const submitAction = (values: any) => {
    const nextData = { ...settingsData, ...values };
    deleteNullKeys(nextData);
    delete nextData.message;
    fetcher.submit(nextData, { method: 'POST', action: '/settings' });
  };
  return (
    <GenericForm
      formId="updateCompanyNameForm"
      className="w-100 mb-3"
      fields={fields}
      submitAction={submitAction}
      navigation={fetcher.state !== 'idle'}
    />
  );
}

const ENV = process.env.NODE_ENV;
const TESTING = process.env.REACT_APP_IS_TESTING;

// Remove those logs after we confirm the ENV variables are loaded server side
console.log('Node Environment (hardcoded)', ENV);
console.log('Testing ENV? Added by create-react-app', TESTING);

const envEnum = {
  development: 'https://dev.leadsboxinvoice.com/api',
  testing: 'https://test.leadsboxinvoice.com/api',
  production: 'https://api.leadsboxinvoice.com/api',
};

export const BASE_URL = TESTING ? envEnum.testing : envEnum[ENV];
// export const BASE_URL = envEnum.testing;
export const register = '/auth/register';
export const registerSuccess = '/auth/register-success';
export const login = '/auth';
export const resetPasswordRequest = '/auth/reset-password-request';
export const resetPasswordRequestSuccess =
  '/auth/reset-password-request-success';
export const forgotPasswordNew = '/auth/reset-password-new';
export const resetPasswordSuccess = '/auth/reset-password-success';

import React from 'react';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import './styles/StripeLinkSuccess.scss';
import { Alert, Button, Spinner } from 'react-bootstrap';
import useStripeConnectionStatus from '../../hooks/use-stripe-connection-status.ts';

function StripeLinkSuccess() {
  const [timeLeft, setTimeLeft] = React.useState(10);
  const { isLoading: isStripeLoading, canMakePayments } =
    useStripeConnectionStatus();
  const navigate = useNavigate();
  const { state: appNavState } = useNavigation();

  const isLoading = appNavState !== 'idle' || isStripeLoading;

  React.useEffect(() => {
    if (timeLeft <= 0) {
      navigate('/');
      return undefined;
    }

    const timer = window.setTimeout(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => window.clearTimeout(timer);
  }, [timeLeft, navigate]);

  return (
    <>
      <Alert className="mt-3" variant="success">
        Stripe Account was successfully generated.
      </Alert>
      <p className="mt-3">
        Your stripe account was successfully updated. You can go to settings to
        change your stripe data.
      </p>
      <div
        className="mt-3 rounded p-3 d-flex align-items-center justify-content-center"
        style={{ minHeight: '4.5rem', backgroundColor: 'var(--bs-gray-200)' }}
      >
        {isLoading && <Spinner size="sm" />}
        {!isLoading && canMakePayments && (
          <p className="small fw-semibold">
            Since you provided a business URL, you can also submit and receive
            payments with Credit Cards.
          </p>
        )}
        {!isLoading && !canMakePayments && (
          <p className="small fw-semibold">
            Remember that since you did not provide a business url, you
            won&apos;t be able to receive or make Credit Card payments.
          </p>
        )}
      </div>
      <p className="mt-3">
        We&apos;ll now attempt to redirect you to the dashboard. If you are not
        redirected, please click on the button below.
      </p>
      <div className="mt-3 fw-semibold">
        {isLoading ? (
          <Spinner size="sm" />
        ) : (
          <span>Redirecting in... {timeLeft || ''}</span>
        )}
      </div>
      <Button className="mt-3" variant="primary" as={Link} to="/">
        Go to dashboard
      </Button>
      <hr />
      <small className="mt-3 text-muted d-block">
        Note that you need to be logged in to view the dashboard. If you are
        not, you will be prompted to login, sorry for the inconvenience!
      </small>
    </>
  );
}

export default StripeLinkSuccess;

import StripePaymentSuccess from '../../components/Payments/StripePaymentSuccess';
import StripeLinkSuccess from '../../components/Settings/StripeLinkSuccess';
import { generateStripeAccount, stripePayment } from './stripeLA';
import LoginPage from '../../pages/LoginPage';

const stripeRoutes = [
  {
    path: '',
    element: <LoginPage />,
    children: [
      {
        path: 'account-link-success',
        element: <StripeLinkSuccess />,
        loader: generateStripeAccount,
      },
      {
        path: 'process-payment',
        element: <StripePaymentSuccess />,
        loader: stripePayment,
      },
    ],
  },
];

export default stripeRoutes;

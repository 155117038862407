import { string } from 'yup';
import { useFetcher } from 'react-router-dom';
import useFormikTemplate from '../../Forms/hooks/useFormikTemplate';

function useProjectManagerForm({
  submitCallback = null,
  endpoint,
  initialValue,
  id,
}) {
  const fetcher = useFetcher();
  const isLoading = fetcher?.state !== 'idle';

  const field = {
    name: 'name',
    label: 'Name',
    initialValue: initialValue || '',
    type: string().required(),
    hideLabel: !!id,
    required: true,
  };

  function submitAction(values) {
    if (submitCallback) {
      submitCallback(values);
    }
    // eslint-disable-next-line no-use-before-define
    formik.handleReset();
    return fetcher.submit(
      { ...values, id: id || null },
      {
        method: 'POST',
        action: endpoint,
      }
    );
  }

  const [formik] = useFormikTemplate({
    initial: [field],
    yupValues: [field],
    submitAction,
    enableReinitialize: false,
  });

  return {
    isLoading,
    formik,
    field,
  };
}

export default useProjectManagerForm;

/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import {
  useParams,
  useRouteLoaderData,
  useFetcher,
  useSubmit,
} from 'react-router-dom';
import * as Yup from 'yup';
import CreateForm from '../Forms/CreateForm/CreateForm';
import UNITS from './constants';
import FormWrapper from '../Forms/builders/FormWrapper';
import useFormikTemplate from '../Forms/hooks/useFormikTemplate';
import SelectItemType from './SelectItemType';
import setFinalPrice from './utils';
import ExtraFields from './ExtraFields';

const DEFAULT_RATES = {
  product_profit_margin: 0,
  sales_tax_percentage: 0,
  service_profit_margin: 0,
};

function CreateProduct() {
  const taxesFetcher = useFetcher();
  const submit = useSubmit();
  const { products } = useRouteLoaderData('products');
  const { productId } = useParams();

  const productData = products.filter(
    (product) => product.id === +productId
  )[0];

  // Ernesto's states galore 🤡 (it has come to my attention that they may not be necessary? but
  // I'm too afraid to remove them and break everything hehe)
  const [itemType, setItemType] = useState(productData?.type || null); // "Product" | "Service" | null
  const [profitType, setProfitType] = useState('net_profit'); // "profit_rate" | "net_profit"

  // Form fields definition
  const fields = [
    {
      formGroup: 'Basic information',
      groupChildren: [
        {
          name: 'category_id',
          label: 'Category',
          initialValue: productData?.category?.id || '',
          formType: 'productCategory',
          type: Yup.string().required(
            'Please add or create a product category'
          ),
          required: true,
          className: 'flex_first-half',
        },
        {
          name: 'item_type',
          label: 'Type',
          initialValue: productData?.type || itemType,
          formType: 'toggleGroup',
          options: [
            { label: 'Product', value: 'Product', color: 'primary' },
            { label: 'Service', value: 'Service', color: 'primary' },
          ],
          type: Yup.string().required('Please select the type'),
          required: true,
          change: (e) => setItemType(e.target.value),
          className: 'flex_second-half',
        },

        {
          name: 'item_name',
          label: itemType === 'Product' ? 'Product Name' : 'Service Name',
          initialValue: productData?.name || '',
          type: Yup.string().required(
            `Please add a name for your ${
              itemType === 'Product' ? 'product' : 'service'
            }`
          ),
          required: true,
          className: 'flex_first-half',
        },
        {
          name: 'description',
          label: 'Description',
          initialValue: productData?.description || '',
          formType: 'textarea',
          type: Yup.string(),
          className: 'flex__row-span-2 flex_second-half',
        },
        {
          label: 'SKU',
          name: 'sku',
          initialValue: productData?.sku || '',
          type: Yup.string(),
          className: 'flex_first-half',
        },
        {
          name: 'provider_name',
          label: 'Provider name',
          initialValue: productData?.provider_name || '',
          type: Yup.string(),
          className: `${itemType === 'Service' ? 'd-none' : null}`,
        },
        {
          name: 'amount_tax_rate',
          label: 'Tax rate',
          initialValue: productData?.amount?.tax_rate || 0,
          formType: 'inputGroup',
          inputMode: 'numeric',
          change: async (e) => {
            await trimNumber('amount_tax_rate', e.target.value);
          },
          type: Yup.number()
            .typeError('Please enter a number')
            .required('Please add a tax rate'),
          config: {
            rightLabel: '%',
          },
          className: itemType === 'Service' ? 'd-none' : '',
        },
        {
          name: 'unit_type',
          label: 'Measurement unit',
          initialValue: productData?.unit?.type || 'UN',
          type: Yup.string(),
          className: 'd-none',
        },
        {
          name: 'unit_quantity',
          label: 'Quantity & Unit',
          initialValue: productData?.unit?.quantity
            ? productData?.unit?.quantity
            : 1,
          type: Yup.number(),
          inputMode: 'numeric',
          formType: 'unit',
          config: {
            hiddenField: 'unit_type',
            options: UNITS,
          },
        },
      ],
    },
    {
      formGroup:
        itemType === 'Product'
          ? 'Product Extra Information'
          : 'Service Budget Information',
      groupChildren: [
        {
          name: 'product_budget_type',
          label: 'Type',
          initialValue: profitType,
          formType: 'toggleGroup',
          options: [
            { label: 'Profit %', value: 'profit_rate', color: 'primary' },
            {
              label: 'Net Profit',
              value: 'net_profit',
              color: 'primary',
            },
          ],
          change: (e) => setProfitType(e.target.value),
          required: true,
          className: 'flex__span-12',
        },
        {
          name: 'amount_cost',
          label: itemType === 'Product' ? 'Cost' : 'Materials/Labor Cost',
          initialValue: productData?.amount?.cost || 0,
          formType: 'inputGroup',
          type: Yup.number()
            .typeError('Please enter a number')
            .required('Please add a price'),
          required: true,
          inputMode: 'numeric',
          change: async (e) => {
            await trimNumber('amount_cost', e.target.value);
            await setFinalPrice(
              formik.values,
              setValue,
              profitType,
              e.target.value,
              true
            );
          },
          config: {
            leftLabel: '$',
          },
          className: 'flex__span-3',
        },
        {
          name: 'amount_profit_net',
          label: 'Profit (net)',
          initialValue:
            productData?.amount?.profit_net ||
            Number(productData?.amount?.price_before_tax) -
              Number(productData?.amount?.cost) ||
            0,
          formType: 'inputGroup',
          type: Yup.number()
            .typeError('Please enter a number')
            .required('Please add the desired profit'),
          required: true,
          inputMode: 'numeric',
          change: async (e) => {
            await trimNumber('amount_profit_net', e.target.value);
            await setFinalPrice(
              formik.values,
              setValue,
              profitType,
              e.target.value
            );
          },
          config: {
            leftLabel: '$',
          },
          disabled: profitType !== 'net_profit',
          className: 'flex__span-3',
        },
        {
          name: 'amount_profit_rate',
          label: 'Profit Rate',
          initialValue: productData?.amount?.profit_rate || 0,
          formType: 'inputGroup',
          type: Yup.number()
            .typeError('Please enter a number')
            .required('Please add the desired profit rate'),
          required: true,
          inputMode: 'numeric',
          change: async (e) => {
            await trimNumber('amount_profit_rate', e.target.value);
            await setFinalPrice(
              formik.values,
              setValue,
              profitType,
              e.target.value
            );
          },
          config: {
            rightLabel: '%',
          },
          disabled: profitType !== 'profit_rate',
          className: profitType === 'profit_rate' ? 'flex__span-3' : 'd-none',
        },
        {
          name: 'amount_price_before_tax',
          label:
            itemType === 'Product' ? 'Final Price (before tax)' : 'Final Rate',
          initialValue: productData?.amount?.price_before_tax || 0,
          formType: 'inputGroup',
          type: Yup.number()
            .typeError('Please enter a number')
            .required('Please fill missing price/rate related fields'),
          inputMode: 'numeric',
          config: {
            leftLabel: '$',
          },
          disabled: true,
          className: 'd-none',
        },
      ],
    },
  ];

  const submitAction = (values) => {
    let nextValues;
    if (values.item_type === 'Service') {
      nextValues = {
        ...values,
        amount_tax_rate: 0,
      };
    } else {
      nextValues = { ...values };
    }

    submit(nextValues, {
      method: 'post',
      encType: 'application/json',
    });
  };

  const [formik] = useFormikTemplate({
    initial: fields,
    yupValues: fields,
    submitAction,
    enableReinitialize: false,
  });

  async function setValue(name, value) {
    await formik.setFieldValue(name, value);
  }

  async function trimNumber(fieldName, fieldValue) {
    // Prevent empty (null) field
    if (fieldValue === '') {
      await setValue(fieldName, 0);
      return;
    }
    // Replace left hand zero's (when it's not a decimal e.g. 0.15)
    const trimmedNumber = fieldValue.replace(/^0+(?=\d)/, '');
    await setValue(
      fieldName,
      Number.isNaN(trimmedNumber) ? Number(trimmedNumber) : trimmedNumber
    );
  }

  useEffect(() => {
    if (taxesFetcher.state === 'idle' && !taxesFetcher.data) {
      taxesFetcher.load('/settings/sales-tax');
    }
    // Update formik state after retrieving the fetcher data
    if (taxesFetcher.data && !productId) {
      setValue(
        'amount_tax_rate',
        Number(
          taxesFetcher.data?.sales_tax_percentage ||
            DEFAULT_RATES.sales_tax_percentage
        )
      );
      // if (itemType === 'Product') {
      //   setValue(
      //     'amount_profit_rate',
      //     Number(
      //       taxesFetcher.data?.product_profit_margin ||
      //         DEFAULT_RATES.product_profit_margin
      //     )
      //   );
      // } else {
      //   setValue(
      //     'amount_profit_rate',
      //     Number(
      //       taxesFetcher.data?.service_profit_margin ||
      //         DEFAULT_RATES.service_profit_margin
      //     )
      //   );
      // }
    }
  }, [taxesFetcher]);

  return !itemType ? (
    <SelectItemType setValue={setValue} setItemType={setItemType} />
  ) : (
    <FormWrapper
      id="createProductForm"
      handleSubmit={formik.handleSubmit}
      isSubmitting={formik.isSubmitting}
      dirty={formik.dirty}
      isValid={formik.isValid}
      className="flex__form-wrapper"
    >
      <CreateForm structure={[fields[0]]} {...formik} />
      <ExtraFields structure={fields[1]} {...formik} profitType={profitType} />
    </FormWrapper>
  );
}

export default CreateProduct;

import { handleResposeAbstraction } from '../../API/fetchUtils';
import invoicesAPI from '../../API/routesAPI/invoicesAPI';

export const createProjectManagerAction = async ({ request }) => {
  const body = await request.formData();
  const response = await invoicesAPI.createProjectManager(body);
  return handleResposeAbstraction({ response });
};
export const getProjectManagersLoader = async () => {
  const response = await invoicesAPI.getProjectManagers();
  return handleResposeAbstraction({ response });
};

export const updateProjectManagerAction = async ({ request }) => {
  const body = await request.formData();
  const id = body.get('id');
  const response = await invoicesAPI.updateProjectManager(body, id);
  return handleResposeAbstraction({ response });
};

export const deleteProjectManagerAction = async ({ params }) => {
  // const projectManagerId = params.get('projectManagerId');
  const { projectManagerId } = params;
  const response = await invoicesAPI.deleteProjectManager(projectManagerId);
  return handleResposeAbstraction({ response });
};

const projectManagersRoutes = [
  {
    path: 'project-managers',
    loader: getProjectManagersLoader,
    action: createProjectManagerAction,
    children: [
      {
        path: ':projectManagerId',
        children: [
          { path: 'delete', action: deleteProjectManagerAction },
          { path: 'update', action: updateProjectManagerAction },
        ],
      },
    ],
  },
];

export default projectManagersRoutes;

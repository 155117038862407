import React from 'react';
import PropTypes from 'prop-types';
import { Offcanvas } from 'react-bootstrap';
import './LBOffCanvas.scss';
import IconButton from '../IconButton/IconButton.tsx';
import { Cross } from '../Icons/Icons';

function Left({ children }) {
  return (
    <div className="col-lg-6 overflow-y-auto lb-offcanvas__grid lb-offcanvas__left">
      <div className="p-2 overflow-x-hidden">{children}</div>
      <div className="px-2" id="offcanvas-action-group-left" />
    </div>
  );
}

function Right({ children }) {
  return (
    <div className="col-lg-6 overflow-y-auto lb-offcanvas__grid lb-offcanvas__right">
      <div className="p-2">{children}</div>
      <div className="px-2" id="offcanvas-action-group-right" />
    </div>
  );
}

function Wrapper({ children }) {
  return (
    <Offcanvas.Body className="container-fluid h-100 position-relative">
      <div className="row h-100">{children}</div>
    </Offcanvas.Body>
  );
}

function Header({ children }) {
  return (
    <Offcanvas.Header
      style={{
        position: 'sticky',
        zIndex: 1,
        top: 0,
        left: 0,
        paddingLeft: 0,
        alignItems: 'flex-start',
        paddingTop: '0.7rem',
        paddingBottom: '2rem',
        minHeight: 80,
        background:
          'linear-gradient( hsla(0, 100%, 100%, 1) 80%, hsla(0, 100%, 100%, 0) 100%)',
      }}
    >
      <Offcanvas.Title style={{ maxWidth: '300px' }}>
        {children}
      </Offcanvas.Title>
    </Offcanvas.Header>
  );
}

function LBOffCanvas({ size, show, handleCloseModal, children }) {
  return (
    <Offcanvas
      show={show}
      onHide={handleCloseModal}
      placement="end"
      // style={customStyles[size]}
      className={`lb-offcanvas__container ${size === 'full' && 'full-size'}`}
    >
      <IconButton onClick={handleCloseModal} className="close-btn">
        <Cross />
      </IconButton>
      {children}
    </Offcanvas>
  );
}

LBOffCanvas.propTypes = {
  handleCloseModal: PropTypes.func,
  size: PropTypes.oneOf(['half', 'full', 'closed']),
  show: PropTypes.bool,
  children: PropTypes.node,
};

Left.propTypes = {
  children: PropTypes.node,
};

Right.propTypes = {
  children: PropTypes.node,
};

Wrapper.propTypes = {
  children: PropTypes.node,
};

Header.propTypes = {
  children: PropTypes.node,
};

LBOffCanvas.Left = Left;
LBOffCanvas.Right = Right;
LBOffCanvas.Wrapper = Wrapper;
LBOffCanvas.Header = Header;

export default LBOffCanvas;

import fetchAPI from '../fetchUtils';

const invoicesAPI = {
  getAll: async (customerId) =>
    fetchAPI.getWithAuth(
      customerId ? `/get-invoices?customerId=${customerId}` : '/get-invoices'
    ),
  create: async (body) =>
    fetchAPI.postWithAuth('/create-invoice', body, 'application/json'),
  update: async (body) =>
    fetchAPI.postWithAuth('/update-invoice', body, 'application/json'),
  delete: async (id) => fetchAPI.getWithAuth(`/delete-invoice/${id}`),
  get: async (id) => fetchAPI.getWithAuth(`/get-invoice-details/${id}`),
  deleteFile: async (body) =>
    fetchAPI.postWithAuth(`/delete-invoice-file`, body),
  nextNumber: async () => fetchAPI.getWithAuth(`/latest-invoice-number`),
  convertEstimate: async (body, id) =>
    fetchAPI.postWithAuth(`/convert-estimate/${id}`, body, 'application/json'),

  // PROJECT MANAGERS
  getProjectManagers: async () => fetchAPI.getWithAuth('/project-managers'),
  createProjectManager: async (body) =>
    fetchAPI.postWithAuth('/project-managers', body),
  updateProjectManager: async (body, id) =>
    fetchAPI.postWithAuth(`/project-managers/update/${id}`, body),
  deleteProjectManager: async (id) =>
    fetchAPI.getWithAuth(`/project-managers/delete/${id}`),
};

export default invoicesAPI;

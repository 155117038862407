/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-use-before-define */
import React, { useState } from 'react';

import {
  useParams,
  useOutletContext,
  useFetcher,
  useSubmit,
} from 'react-router-dom';
import * as Yup from 'yup';
import CreateForm from '../Forms/CreateForm/CreateForm';
import File from '../Forms/FileDragAndDrop/File';
import FormWrapper from '../Forms/builders/FormWrapper';
import useFormikTemplate from '../Forms/hooks/useFormikTemplate';
import createFileForm from '../Forms/helpers/createFileForm';
import { formatPhoneNumber, phoneUS } from '../Forms/yup/customValidations';

function CreateMerchant() {
  const merchant = useOutletContext();
  const merchantForm = [
    {
      formGroup: merchant?.name ? 'Update Merchant' : 'New Merchant',
      groupChildren: [
        {
          name: 'name',
          label: 'Merchant name',
          initialValue: merchant?.name || '',
          type: Yup.string().required('This is a mandatory field'),
          required: true,
        },
        {
          name: 'contact_person',
          label: 'Contact person',
          initialValue: merchant?.contact_person || '',
          type: Yup.string(),
        },
        {
          name: 'phone',
          label: 'Phone',
          formType: 'phone',
          initialValue: merchant?.phone || '',
          type: phoneUS,
        },
        {
          name: 'website',
          label: 'Website',
          initialValue: merchant?.website || '',
          type: Yup.string().matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Enter correct url format: https://www.example.com'
          ),
        },
      ],
    },
    {
      formGroup: 'Address',
      groupChildren: [
        {
          name: 'autocomplete_address',
          label: 'Search address',
          className: 'input-wide',
          initialValue: '',
          type: Yup.string(),
          formType: 'autocompleteAddress',
          fieldNames: {
            street: 'address_street_address_1',
            city: 'address_city',
            state: 'address_state',
            zipcode: 'address_zipcode',
            country: 'address_country',
          },
        },
        {
          name: 'address_street_address_1',
          formType: 'text',
          label: 'Address Line 1',
          initialValue: merchant?.address?.street_address_1 || '',
          type: Yup.string(),
          autocomplete: 'address-line1',
        },
        {
          name: 'address_street_address_2',
          formType: 'text',
          label: 'Address Line 2',
          initialValue: merchant?.address?.street_address_2 || '',
          type: Yup.string(),
        },
        {
          name: 'address_zipcode',
          formType: 'text',
          label: 'ZIP Code',
          initialValue: merchant?.address?.zipcode || '',
          type: Yup.string(),
        },
        {
          name: 'address_city',
          formType: 'text',
          label: 'City',
          initialValue: merchant?.address?.city || '',
          type: Yup.string(),
        },
        {
          name: 'address_state',
          formType: 'text',
          label: 'State',
          initialValue: merchant?.address?.state || '',
          type: Yup.string(),
        },
        {
          name: 'address_country',
          formType: 'text',
          label: 'Country',
          initialValue: merchant?.address?.country || '',
          type: Yup.string(),
        },
      ],
    },
  ];
  const notes = [
    // NOTES
    {
      formGroup: 'Notes',
      groupChildren: [
        {
          name: 'notes',
          label: 'Notes',
          initialValue: merchant?.notes || '',
          type: Yup.string(),
          formType: 'textarea',
          className: 'input-wide',
        },
      ],
    },
  ];
  const attachment = [
    {
      name: 'image',
      initialValue: merchant?.image || '',
    },
  ];

  const { merchantId } = useParams();
  const formStructure = [...merchantForm, ...notes, ...attachment];

  const reactRouterSubmit = useSubmit();

  const submitAction = async (values) => {
    if (merchantId) {
      // Update action
      const nextValues = createFileForm(
        { ...values, phone: formatPhoneNumber(values.phone) },
        formData
      );
      nextValues.append('id', merchantId);
      reactRouterSubmit(nextValues, {
        method: 'post',
        encType: 'multipart/form-data',
      });
      // const response = await updateMerchantAction({ request: nextValues });
      // if (response.status === 302) {
      //   const linkTo = response.headers.get('location');
      //   navigate(linkTo);
      // }
    } else {
      // Create action (new)
      const nextValues = createFileForm(
        { ...values, phone: formatPhoneNumber(values.phone) },
        formData
      );
      reactRouterSubmit(nextValues, {
        method: 'post',
        encType: 'multipart/form-data',
      });
      // const response = await createMerchantAction({ request: nextValues });
      // if (response.status === 302) {
      //   const linkTo = response.headers.get('location');
      //   navigate(linkTo);
      // }
    }
    formik.setSubmitting(false);
  };

  const [formik] = useFormikTemplate({
    initial: formStructure,
    yupValues: formStructure,
    submitAction,
  });

  // FILE COMPONENT STATE AND FUNCTIONS START
  // Tracks save the file sin this component cause formik is tracking them but no saving them
  const [formData, setFormData] = useState({});

  // Remove file from db when the user its updating
  const fetcher = useFetcher();

  const deleteFile = (name) => {
    if (merchant[name] === formik.values[name] && merchant[name] !== null) {
      if (fetcher.state === 'submiting') return;
      fetcher.submit(
        {
          module: 'merchant',
          id: merchant.id,
          file: name,
        },
        { method: 'post', action: '/expenses/delete-expense-files' }
      );
    }
  };

  return (
    <FormWrapper
      id="newMerchantForm"
      handleSubmit={formik.handleSubmit}
      isSubmitting={formik.isSubmitting}
      dirty={formik.dirty}
      isValid={formik.isValid}
    >
      <CreateForm structure={merchantForm} {...formik} />
      <div className="row">
        <div className="col-md-6">
          <CreateForm structure={notes} {...formik} />
        </div>

        <div className="col-md-6">
          <h5 className="mb-4 mt-5">Files</h5>
          <File
            value={formik.values.image}
            setFieldValue={formik.setFieldValue}
            setFormData={setFormData}
            deleteFile={deleteFile}
            name="image"
            label="Attachments"
          />
        </div>
      </div>
    </FormWrapper>
  );
}

export default CreateMerchant;

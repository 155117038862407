import PublicPdfPreview from '../../components/PDF/publicPreview/PublicPdfPreview.tsx';
import ErrorPage from './ErrorPage';
import {
  publicEstimateLoader,
  publicInvoiceLoader,
  publicPaymentLoader,
} from './publicPreviewLA';

const publicPreviewRoutes = [
  {
    path: 'preview-pdf',
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'invoice',
        loader: publicInvoiceLoader,
        element: <PublicPdfPreview />,
      },
      {
        path: 'payment',
        loader: publicPaymentLoader,
        element: <PublicPdfPreview />,
      },
      {
        path: 'estimate',
        loader: publicEstimateLoader,
        element: <PublicPdfPreview />,
      },
    ],
  },
];

export default publicPreviewRoutes;

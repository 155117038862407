import React from 'react';
import {
  NavLink,
  Link,
  useNavigate,
  useMatches,
  useRouteLoaderData,
} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import SectionContent from '../UI/SectionContent';
import iconLeft from '../../img/icon-left-arrow.png';
import Breadcrumbs from '../UI/BreadCrumbs';
import HelpTooltip from '../HelpTooltip/HelpTooltip.tsx';

import './PageHeader.scss';

function PageHeader({ children }) {
  const matches = useMatches();
  const {
    user: { subscription },
  } = useRouteLoaderData('root');
  const tabsArr = matches
    .filter((match) => match.handle?.sublinks)
    .map((match) => match.handle.sublinks(match));

  const tabs = tabsArr.length > 0 ? tabsArr[tabsArr.length - 1] : null;
  const lastIndexOfMatch = matches[matches.length - 1];
  const lastMatch = lastIndexOfMatch.handle?.linkTo
    ? lastIndexOfMatch
    : matches.filter((match) => match.handle?.linkTo?.end)[0];

  const linkTo = lastMatch && {
    to:
      lastMatch.handle?.linkTo &&
      `${lastMatch?.pathname}${lastMatch?.handle?.linkTo?.to}`,
    label: lastMatch.handle?.linkTo.label,
    state:
      lastMatch.handle?.linkTo?.state &&
      lastMatch.handle?.linkTo?.state(lastMatch),
    toolTip: lastMatch.handle?.linkTo?.toolTip,
  };
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const { message: tooltipMessage, disableButton } = lastMatch?.handle?.linkTo
    ?.toolTip
    ? lastMatch.handle.linkTo.toolTip(
        matches.find((match) => match.id === 'root')?.data?.user
          ?.onboarding_helpers
      )
    : {};

  return (
    <div className="generic-page">
      <div id="breadcrumbsPortal" className="px-2 p-md-0" />
      <div className="header_card">
        <div className="d-flex justify-content-between align-items-center header_min-heigth">
          <div className="d-flex align-items-center">
            <Button
              type="button"
              variant="ligth"
              onClick={goBack}
              className="p-0"
            >
              <img src={iconLeft} alt="" style={{ width: '20px' }} />
            </Button>
            <h5 className="mb-0 ms-2">
              <Breadcrumbs />
            </h5>
          </div>
          {linkTo && !lastMatch.handle.linkTo.noShow && (
            <div className="d-flex align-items-center gap-3">
              {lastMatch.handle.linkTo?.toolTip && tooltipMessage && (
                <HelpTooltip>{tooltipMessage}</HelpTooltip>
              )}
              <Button
                as={Link}
                disabled={disableButton}
                className={disableButton ? 'btn disabled lb-btn-disabled' : ''}
                to={linkTo.to}
                state={linkTo.state}
              >
                {linkTo.label}
              </Button>
            </div>
          )}
        </div>

        <nav className="tabs">
          {tabs && (
            <ul className="nav">
              {tabs.map(
                ({
                  to,
                  label,
                  end = false,
                  tiersAllowed = [],
                  helpTooltip,
                }) => {
                  const tabForbidden =
                    tiersAllowed?.length > 0 &&
                    !tiersAllowed.includes(subscription.tier);
                  return (
                    <li
                      key={to}
                      className="d-flex align-items-baseline gap-2"
                      style={{
                        cursor: tabForbidden ? 'not-allowed' : 'cursor',
                      }}
                    >
                      <NavLink
                        to={to}
                        className={
                          tabForbidden
                            ? 'd-inline-flex gap-2 nav-link nav-item disabled'
                            : 'd-inline-flex gap-2 nav-link nav-item'
                        }
                        end={end}
                      >
                        {label}
                        {helpTooltip && (
                          <HelpTooltip
                            size="sm"
                            style={{ pointerEvents: 'all' }}
                            onClick={(e) => e.stopPropagation()}
                          >
                            {helpTooltip}
                          </HelpTooltip>
                        )}
                      </NavLink>
                    </li>
                  );
                }
              )}
            </ul>
          )}
        </nav>
      </div>

      <SectionContent className="mt-4">{children}</SectionContent>
    </div>
  );
}

PageHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageHeader;

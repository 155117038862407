import GetAccount from '../../components/Settings/GetAccount';
import SalesSettings from '../../components/Settings/SalesSettings';
import DefaultMessages from '../../components/Settings/DefaultMessages';
import DocumentTemplateSelector from '../../components/Settings/DocumentTemplateSelector/DocumentTemplateSelector';
import MessageSettingsInvoice from '../../components/Settings/Messages/MessageSettingsInvoice';
import MessageSettingsReviews from '../../components/Settings/Messages/MessageSettingsReviews';
import MessageSettingsEstimate from '../../components/Settings/Messages/MessageSettingsEstimate';
import MessageSettingsReceipt from '../../components/Settings/Messages/MessageSettingsReceipt';
import MessageSettingsContactForm from '../../components/Settings/Messages/MessageSettingsContactForm';
import {
  accountSettingsAction,
  accountSettingsLoader,
  cancelSubscriptionAction,
  contactSettingsAction,
  contactSettingsLoader,
  createVehicleAction,
  deleteSettingsFilesAction,
  deleteVehicleAction,
  documentTemplatesAction,
  documentTemplatesLoader,
  estimatesSettingsAction,
  estimatesSettingsLoader,
  getStripeConnectionStatus,
  invoiceSettingLoader,
  invoiceSettingsAction,
  planListAction,
  planListLoader,
  receiptSettingsAction,
  receiptSettingsLoader,
  resumeSubsriptionAction,
  reviewsSettingsAction,
  reviewsSettingsLoader,
  stripeUrlSettingsLoader,
  subscriptionLoader,
  taxSettingsAction,
  taxSettingsLoader,
  updateMileageAction,
  updateSubscriptionDev,
  updateVehicleAction,
  vehicleLoader,
  vehiclesLoader,
} from './settingsLA';
import GenericPage from '../../pages/GenericPage';
import PaymentSetUp from '../../components/Settings/PaymentSetup/PaymentSetUp';
import tradesRoutes from '../trades/tradesRoutes';
import ManageSubscription from '../../components/Settings/ManageSubscription';
import SubscriptionTierManager from '../../pages/SubscriptionTierManager';
import AssetsPage from '../../components/Settings/Mileages/MileagesPage';
import CreateVehicleModal from '../../components/Settings/Mileages/CreateVehicleModal';
import Vehicles from '../../components/Settings/Mileages/Vehicles';
import manageCredentialsRoutes from './manageCredentialsRoutes.tsx';
import PlanList from '../../components/Settings/ManageSubscription/PlanList.tsx';

const settingsRoutes = [
  {
    path: 'settings',
    element: <GenericPage />,
    action: accountSettingsAction,

    handle: {
      crumb: () => 'Settings',
      sublinks: () => [
        {
          label: 'Account',
          to: '/settings',
          end: true,
        },
        {
          label: 'Subscription',
          to: '/settings/subscription',
        },
        {
          label: 'Payment Set Up',
          to: '/settings/payment-setup',
        },
        {
          label: 'Sales',
          to: '/settings/sales-tax',
        },
        {
          label: 'Templates',
          to: '/settings/templates',
        },
        {
          label: 'Messages',
          to: '/settings/messages/invoices',
        },
        {
          label: 'Mileages',
          to: '/settings/mileages',
          tiersAllowed: ['tier_3'],
          helpTooltip:
            'Manage the yearly mileage of your business vehicles. Included in ELITE subscription.',
        },
      ],
    },
    children: [
      {
        path: '',
        element: <GetAccount />,
        loader: accountSettingsLoader,
        action: accountSettingsAction,
        children: [
          {
            ...tradesRoutes,
          },
          ...manageCredentialsRoutes,
        ],
      },
      {
        path: 'accountInfo',
        loader: accountSettingsLoader,
      },
      {
        path: 'subscription',

        children: [
          {
            path: '',
            id: 'sub-details',
            loader: subscriptionLoader,
            element: <ManageSubscription />,
            children: [
              {
                path: 'plan-list',
                loader: planListLoader,
                action: planListAction,
                element: <PlanList />,
              },
            ],
          },
          {
            path: 'cancel',
            action: cancelSubscriptionAction,
          },
          {
            path: 'resume',
            action: resumeSubsriptionAction,
          },
        ],
      },
      {
        path: 'payment-setup',
        element: <PaymentSetUp />,
        loader: stripeUrlSettingsLoader,
      },
      {
        path: 'sales-tax',
        element: <SalesSettings />,
        action: taxSettingsAction,
        loader: taxSettingsLoader,
      },
      {
        path: 'templates',
        element: <DocumentTemplateSelector />,
        loader: documentTemplatesLoader,
        action: documentTemplatesAction,
      },
      {
        path: 'messages',
        element: <DefaultMessages />,
        children: [
          {
            path: 'reviews',
            element: <MessageSettingsReviews />,
            loader: reviewsSettingsLoader,
            action: reviewsSettingsAction,
          },
          {
            path: 'invoices',
            element: <MessageSettingsInvoice />,
            loader: invoiceSettingLoader,
            action: invoiceSettingsAction,
          },
          {
            element: <MessageSettingsEstimate />,
            path: 'estimates',
            loader: estimatesSettingsLoader,
            action: estimatesSettingsAction,
          },
          {
            path: 'receipts',
            element: <MessageSettingsReceipt />,
            loader: receiptSettingsLoader,
            action: receiptSettingsAction,
          },

          {
            path: 'contact-form',
            element: <MessageSettingsContactForm />,
            loader: contactSettingsLoader,
            action: contactSettingsAction,
          },
        ],
      },
      {
        path: 'mileages',
        loader: vehiclesLoader,
        action: updateMileageAction,
        id: 'vehicles',
        element: <AssetsPage />,
        children: [
          {
            path: 'vehicles',
            element: <Vehicles />,
            children: [
              {
                path: 'new-vehicle',
                action: createVehicleAction,
                element: <CreateVehicleModal />,
              },
              {
                path: 'update-vehicle',
                loader: vehicleLoader,
                action: updateVehicleAction,
                element: <CreateVehicleModal />,
              },
              {
                path: 'delete-vehicle',
                action: deleteVehicleAction,
              },
            ],
          },
        ],
      },
      {
        path: 'delete-settings-files',
        action: deleteSettingsFilesAction,
      },
      {
        path: 'dev-subscription-manage',
        element: <SubscriptionTierManager />,
        action: updateSubscriptionDev,
      },
      {
        path: 'stripe-connection-status',
        loader: getStripeConnectionStatus,
      },
    ],
  },
];

export default settingsRoutes;

import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';

// Yup.addMethod(Yup.type, 'isPhoneNumber', function () {
//   return this.test(
//     'isPhoneNumber',
//     'Please enter a valid phone number.',
//     function (value) {
//       const { path, createError } = this;
//       if (!value) return true;
//       return isPossiblePhoneNumber(value, { defaultCountry: 'US' });
//       // return someCondition || conditionTwo || createError(...);
//     }
//   );
// });

export const isPhoneNumber = Yup.string().test({
  name: 'isPhoneNumber',
  exclusive: false,
  params: {},
  message: 'Please enter a valid phone number.',
  test(value) {
    // Prevent check if phone is null | ""
    if (!value) {
      return true;
    }

    // This validator is a little bit lax but this is recommended
    // by the phone input's developer
    return isPossiblePhoneNumber(value, {
      defaultCountry: 'US',
    });
  },
});

export const formatPhoneNumber = (value) => {
  if (!value) return value;
  const cleaned = value.replace(/\D/g, '');
  return cleaned.length === 10
    ? `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`
    : value;
};

export const phoneUS = Yup.string().test({
  name: 'phoneUS',
  message: 'Please enter a valid phone number.',
  test(value) {
    if (!value) return true; // Allow empty values (use `.required()` if necessary)

    const cleaned = value.replace(/\D/g, ''); // Remove all non-numeric characters
    return cleaned.length === 10;
  },
});

export const textarea = Yup.string().max(
  400,
  ({ max, value }) => `${value.length} / ${max}`
);

export const textInput = Yup.string()
  .min(4, ({ min }) => `Must be at least ${min} characters long.`)
  .max(50, ({ max, value }) => `${value.length} / ${max}`);

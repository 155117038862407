/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useRef, useState, useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import useContainerDimensions from './useContainerDimensions';
import IconButton from '../../UI/IconButton/IconButton.tsx';
import { Carat, CaratBack } from '../../UI/Icons/Icons';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function LoadingState() {
  return (
    <div
      style={{
        display: 'grid',
        placeContent: 'center',
        backgroundColor: 'var(--bs-gray-100)',
        width: '100%',
        height: '100%',
      }}
    >
      <Spinner />
    </div>
  );
}

function PDFViewer({
  title,
  className,
  instance,
  innerRef,
  hidden = false,
  ...props
}) {
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const containerRef = useRef(null);
  const dimensions = useContainerDimensions(containerRef);
  const pdfSize = instance?.blob?.size || 0;
  const isPdfEmpty = pdfSize < 1000;

  // Memoize the PDF file to prevent unnecessary renders
  const memoizedPdfFile = useMemo(() => instance?.url, [instance]);

  const onDocumentLoadSuccess = (pdf) => {
    setNumPages(pdf.numPages);
  };

  const nextPage = () => {
    if (pageNumber < numPages) setPageNumber((prev) => prev + 1);
  };
  const prevPage = () => {
    if (pageNumber > 1) setPageNumber((prev) => prev - 1);
  };

  return (
    <div
      ref={containerRef}
      style={{
        display: hidden ? 'none' : 'block',
        position: 'relative',
        width: '100%',
        aspectRatio: '8.5 / 11',
        height: 'auto',
        border: '1px solid var(--bs-gray-200)',
        borderRadius: 8,
        overflow: 'hidden',
        ...props.style,
      }}
    >
      {!memoizedPdfFile || instance?.loading || isPdfEmpty ? (
        <LoadingState />
      ) : (
        <>
          <iframe
            title={title}
            ref={innerRef}
            src={memoizedPdfFile}
            style={{ display: 'none' }}
          />
          <Document
            file={memoizedPdfFile}
            loading={
              <div
                style={{
                  display: 'grid',
                  placeContent: 'center',
                  backgroundColor: 'var(--bs-gray-100)',
                  width: '100%',
                  height: 'auto',
                }}
              >
                <Spinner />
              </div>
            }
            onLoadSuccess={onDocumentLoadSuccess}
            className={className}
            {...props}
          >
            {numPages > 0 && (
              <Page
                width={dimensions.width}
                pageNumber={pageNumber}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            )}

            <div
              style={{
                display: 'flex',
                position: 'absolute',
                alignItems: 'center',
                borderRadius: 8,
                gap: 8,
                left: '50%',
                bottom: 16,
                transform: 'translateX(-50%)',
                backgroundColor: 'var(--bs-white)',
                border: '1px solid var(--bs-gray-300)',
              }}
            >
              <IconButton onClick={prevPage}>
                <CaratBack />
              </IconButton>
              <p>
                {pageNumber} / {numPages}
              </p>
              <IconButton onClick={nextPage}>
                <Carat />
              </IconButton>
            </div>
          </Document>
        </>
      )}
    </div>
  );
}

export default PDFViewer;

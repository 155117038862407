import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import { I_Colors, I_Data } from '../propTypes';
import FooterContent from '../FooterContent';

const s = StyleSheet.create({
  container: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    bottom: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    paddingRight: 40,
    paddingLeft: 40,
    height: 26,
  },
  col: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 3,
    fontSize: 8,
  },
});

function Footer({ data, colors }: { data: I_Data; colors: I_Colors }) {
  return (
    <View
      style={{
        ...s.container,
        backgroundColor: colors.color1,
        color: colors.color1text,
      }}
      fixed
    >
      <FooterContent data={data} s={s} foregroundColor={colors.color1text} />
    </View>
  );
}

export default Footer;

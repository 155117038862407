import { handleResposeAbstraction } from '../../API/fetchUtils';
import publicAPI from '../../API/routesAPI/publicAPI';
import { parseEstimate } from '../estimates/estimatesLA';
import { parseInvoice } from '../invoices/invoicesLA';
import { parsePayment } from '../payments/paymentsLA';

function transformPayment({ payment, invoice, logoBase64 }) {
  const transformedData = {
    id: payment.id,
    amount: invoice.invoice.amount,
    customer: invoice.customer,
    dates: {
      dateCreated: payment.date,
    },
    items: invoice.invoice.items,
    notes: {
      statementNotes: payment.statement_notes,
    },
    number: payment.number,
    invoiceNumber: invoice.invoice.number,
    status: invoice.status,
    type: 'receipt',
    user: {
      ...invoice.user,
      company: {
        ...invoice.user.company,
        logo: logoBase64,
      },
    },
    paymentMethod: payment.method_id,
    checkNumber: payment.check,
    payementsTotal: payment.amount,
  };

  const colors = {
    color1: invoice.template.primary_color_bg || '#00334f',
    color2: invoice.template.secondary_color_bg || '#186fd7',
    color3: invoice.template.third_color_bg || '#001825',
    color4: 'var(--bs-teal)',
    color1text: invoice.template.primary_color_text || '#FFF',
    color2text: invoice.template.secondary_color_text || '#FFF',
    color3text: invoice.template.third_color_text || '#FFF',
    color4text: 'var(--bs-white)',
    bodyText: 'var(--bs-gray-900)',
  };

  return {
    data: transformedData,
    colors,
    layoutNumber: invoice.template.type ? Number(invoice.template.type) : 1,
  };
}

function transformInvoice({ data }, logo) {
  const transformedData = {
    id: data.invoice.id,
    amount: data.invoice.amount,
    customer: data.customer,
    dates: data.invoice.dates,
    items: data.invoice.items,
    notes: data.invoice.notes,
    number: data.invoice.number,
    status: data.invoice.status,
    type: 'invoice',

    user: {
      ...data.user,
      company: {
        ...data.user.company,
        logo,
      },
    },
  };

  const colors = {
    color1: data.template.primary_color_bg || '#00334f',
    color2: data.template.secondary_color_bg || '#186fd7',
    color3: data.template.third_color_bg || '#001825',
    color4: 'var(--bs-teal)',
    color1text: data.template.primary_color_text || '#FFF',
    color2text: data.template.secondary_color_text || '#FFF',
    color3text: data.template.third_color_text || '#FFF',
    color4text: 'var(--bs-white)',
    bodyText: 'var(--bs-gray-900)',
  };
  return {
    data: transformedData,
    colors,
    layoutNumber: data.template.type ? Number(data.template.type) : 1,
  };
}

export function transformEstimate({ data }, logoBase64) {
  const transformedData = {
    id: data.estimate.id,
    amount: data.estimate.amount,
    customer: data.customer,
    dates: data.estimate.dates,
    items: data.estimate.items,
    notes: data.estimate.notes,
    number: data.estimate.number,
    status: data.estimate.status,
    type: 'estimate',
    user: {
      ...data.user,
      company: {
        ...data.user.company,
        logo: logoBase64,
      },
    },
  };

  const colors = {
    color1: data.template.primary_color_bg || '#00334f',
    color2: data.template.secondary_color_bg || '#186fd7',
    color3: data.template.third_color_bg || '#001825',
    color4: 'var(--bs-teal)',
    color1text: data.template.primary_color_text || '#FFF',
    color2text: data.template.secondary_color_text || '#FFF',
    color3text: data.template.third_color_text || '#FFF',
    color4text: 'var(--bs-white)',
    bodyText: 'var(--bs-gray-900)',
  };
  return {
    data: transformedData,
    colors,
    layoutNumber: data.template.type ? Number(data.template.type) : 1,
  };
}

export const publicInvoiceLoader = async ({ request }) => {
  const url = new URL(request.url);
  const token = url.searchParams.get('token');
  const response = await publicAPI.getInvoice(token);
  let result = await response.json();

  if (result?.invoice) {
    result = transformInvoice(
      parseInvoice(result),
      result.user_details.logo_base64
    );
  }

  if (response.status !== 200) {
    return null;
  }

  return handleResposeAbstraction({ response, result });
};

export const publicPaymentLoader = async ({ request }) => {
  const url = new URL(request.url);
  const token = url.searchParams.get('token');
  const paymentResponse = await publicAPI.getPayment(token);

  // We need to transform this
  let paymentResult = await paymentResponse.json();

  const invoiceToken = paymentResult.data.invoice_token;

  if (!invoiceToken) return null;

  const invoiceResponse = await publicAPI.getInvoice(invoiceToken);
  const invoiceResult = await invoiceResponse.json();

  if (paymentResult?.message === 'success') {
    paymentResult = transformPayment({
      payment: parsePayment(paymentResult.data).payment,
      invoice: parseInvoice(invoiceResult).data,
      logoBase64: invoiceResult.user_details.logo_base64,
    });
  }

  if (paymentResponse.status !== 200 || invoiceResponse.status !== 200) {
    return null;
  }

  return handleResposeAbstraction({
    response: paymentResponse,
    result: paymentResult,
  });
};

export const publicEstimateLoader = async ({ request }) => {
  const url = new URL(request.url);
  const token = url.searchParams.get('token');
  const estimateResponse = await publicAPI.getEstimate(token);

  if (estimateResponse.status !== 200) return null;

  let estimateResult = await estimateResponse.json();

  if (estimateResult?.message === 'success') {
    estimateResult = transformEstimate(parseEstimate(estimateResult), null);
  }

  return handleResposeAbstraction({
    response: estimateResponse,
    result: estimateResult,
  });
};

import fetchAPI from '../fetchUtils';

const publicAPI = {
  getInvoice: async (token) =>
    fetchAPI.getAnonymous(`/get-public-invoice-details/${token}`),
  getPayment: async (token) =>
    fetchAPI.getAnonymous(`/get-public-payment-details/${token}`),
  getEstimate: async (token) =>
    fetchAPI.getAnonymous(`/get-public-estimate-details/${token}`),
};

export default publicAPI;

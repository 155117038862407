const name = {
  reg: 'Isabella Renovations', // [20]
  max: 'Isabella Renovations & constructions Ltd', // [40]
  min: 'IR', // [2]
};

const sloganTest = {
  reg: 'Transforming Spaces-', // [20]
  max: 'Transforming Spaces and Enriching Lives-', // [40]
  min: '', // [0]
};

const email = {
  reg: 'isabella@renovations.com',
  max: 'renovations-constructions-info@isabella-renovations.com',
  min: 'info@ira.com',
};

export default function documentMockData(user, data, type, slogan) {
  const visualTest = 'min';

  return {
    id: 1,
    amount: {
      balance: '0.00',
      paid: '29.31',
      subtotal: '0.00',
      tax: '2.31',
      total: '27.00',
    },
    customer: {
      id: 251,
      mobile: '+543416966002',
      name: 'Johnathan Homeowner',
      email: 'johnathan.homeowner@example.com',
      phone: '+1234567890',
      address: {
        zipcode: '54321',
        street_address_1: '123 Dream Home St',
        street_address_2: 'Floor 2',
        city: 'Home City',
        state: 'Dream State',
        country: 'Dreamland',
        full_address:
          '123 Dream Home St, Floor 2, Home City, Dream State, Dreamland-54321',
      },
    },
    dates: {
      dateCreated: '2024-01-24',
      dateDue: '2024-02-23',
    },
    items: [
      {
        item_price_before_tax: '700',
        category_id: 201,
        category_name: 'Category Name',
        description: null,
        product_id: 101,
        item_id: 1,
        product_name: 'Premium Paint',
        quantity: '2',
        price_before_tax: '350',
        tax_amount: '10',
        tax_rate: '70',
        unit_quantity: 'Gal',
      },
      {
        item_price_before_tax: '125',
        category_id: 201,
        category_name: 'Category Name',
        description: null,
        product_id: 101,
        item_id: 1,
        product_name: 'Premium Paint',
        quantity: '5',
        price_before_tax: '25',
        tax_amount: '9.375',
        tax_rate: '7.5',
        unit_quantity: 'Gal',
      },
      {
        item_price_before_tax: '1200',
        category_id: 202,
        category_name: 'Category Name',
        description:
          'Carpentry work for full house renovation involves a comprehensive approach, including structural enhancements and custom woodworking tailored to enhance the aesthetic and functional aspects of your home. \nElectrical work for lighting fixtures goes beyond simple installations. We focus on designing and implementing energy-efficient and aesthetically pleasing solutions that illuminate your living spaces with style and functionality.\nPlumbing tasks for the kitchen and bathroom cover a spectrum of services aimed at optimizing both functionality and style. From installing modern fixtures to ensuring proper water flow, our plumbing experts take care of every detail. \nEnsuring precision and quality in every task is our commitment to delivering exceptional results. Our team pays meticulous attention to detail, ensuring that every aspect of the renovation process meets the highest standards.',
        product_id: 102,
        item_id: 2,
        product_name: 'Skilled Labor',
        quantity: '40',
        price_before_tax: '30',
        tax_amount: '108',
        tax_rate: '9.00',
        unit_quantity: 'Hours',
      },

      {
        item_price_before_tax: '1000',
        category_id: 203,
        category_name: 'Category Name',
        description:
          'Installing durable floor tiles for a modern design involves a meticulous process aimed at enhancing the overall aesthetic and functionality of your space. Our expert team carefully selects and installs tiles that not only withstand daily wear and tear but also contribute to a contemporary and stylish atmosphere. From the selection of materials to the precise installation, we focus on creating a durable foundation that complements your vision for a modern and inviting living space.\nThe upgrade of your kitchen with high-quality tiles is a transformative endeavor that encompasses more than just aesthetics. We source premium tiles known for their durability, resilience, and aesthetic appeal. Our team of skilled craftsmen ensures that every tile is strategically placed to optimize the visual impact and functionality of your kitchen. The result is a kitchen space that not only meets high standards in quality but also elevates your daily culinary experiences.\nOur commitment to providing aesthetically pleasing and easy-to-maintain tiles extends beyond the installation process. We take pride in offering a wide range of tile options, each selected with an emphasis on both beauty and practicality. These tiles not only enhance the visual appeal of your space but also simplify the maintenance process, ensuring that your investment in a stylish and modern design remains effortlessly beautiful over time.',
        product_id: 103,
        item_id: 3,
        product_name: 'Floor Tiles',
        quantity: '100',
        price_before_tax: '10',
        tax_amount: '100',
        tax_rate: '10.00',
        unit_quantity: 'Sqft',
      },
    ],
    notes: {
      description:
        'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. N',
      statementNotes:
        'A character can be any letter, number, punctuation, special character, or space. Each of these characters takes up one byte of space in a computers memory. Some Unicode characters, like emojis and some letters in non-Latin alphabets, take up two bytes of space and therefore count as two characters. Use our character counter tool below for an accurate count of your characters. A character can be any letter, number, punctuation, special character, or space. Each of these characters takes up one byte of space in a computers memory. Some Unicode characters, like emojis and some letters in non-Latin alphabets, take up two bytes of space and therefore count as two characters. Use our character counter tool below for an accurate count of your characters.',
      customerNotes:
        'A character can be any letter, number, punctuation, special character, or space. Each of these characters takes up one byte of space in a computers memory. Some Unicode characters, like emojis and some letters in non-Latin alphabets, take up two bytes of space and therefore count as two characters. Use our character counter tool below for an accurate count of your characters. A character can be any letter, number, punctuation, special character, or space. Each of these characters takes up one byte of space in a computers memory. Some Unicode characters, like emojis and some letters in non-Latin alphabets, take up two bytes of space and therefore count as two characters. Use our character counter tool below for an accurate count of your characters.',
    },
    number: 'I-202400032',
    invoiceNumber: 'I-202400032',
    status: 3,
    type,
    user: {
      company: {
        name: user?.user_company || name[visualTest],
        slogan: slogan || data?.company_slogan || sloganTest[visualTest],
        logo: user?.company_logo_base64 || '',
      },
      contact: {
        address: {
          street_address_1: user?.user_address_1 || '123 Revamp Boulevard',
          street_address_2: user?.user_address_2 || 'Suite 300',
          city: user?.user_city || 'Renovationville',
          state: user?.user_state || 'Renovation State',
          country: user?.user_company || 'Renovationland',
          zipcode: user?.user_zipcode || '98765',
          full_address:
            '123 Revamp Boulevard, Suite 300, Renovationville, Renovation State, Renovationland-98765',
        },
        phone: user?.user_phone || '+9876543210',
        mobile: user?.user_phone || 'missing from db',
        email: user?.user_email || email[visualTest],
      },
    },
    paymentMethod: 1,
    checkNumber: null,
    paymentsTotal: '29.31',
  };
}

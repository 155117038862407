/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import { useNavigation } from 'react-router-dom';
import { Col, Row, Spinner } from 'react-bootstrap';
import CreateForm from '../Forms/CreateForm';

function CustomerInfoForm({ formik, structure }) {
  const { state } = useNavigation();

  const isLoading = state !== 'idle';
  const noEmail = !formik.values?.customer_email;
  const noAddress = !formik.values?.billing_address;
  const noCustomer = noEmail && noAddress;

  let renderInfoBox;

  if (isLoading) {
    renderInfoBox = <Spinner />;
  }

  if (noCustomer) {
    renderInfoBox = <p>Choose a customer</p>;
  }

  if (!noEmail || !noAddress) {
    renderInfoBox = (
      <Row className="gy-3">
        <Col
          md={6}
          className="d-flex flex-column"
          style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
        >
          <span className="customer-info__label">Email:</span>
          {formik.values?.customer_email || 'Missing email'}
        </Col>
        <Col md={6} className="d-flex flex-column">
          <span className="customer-info__label">Address:</span>
          {formik.values?.billing_address || 'Missing address'}
        </Col>
      </Row>
    );
  }

  return (
    <>
      <CreateForm {...formik} structure={structure} />
      <div className="customer-info__container p-3 rounded container-fluid">
        {renderInfoBox}
      </div>
    </>
  );
}

export default CustomerInfoForm;

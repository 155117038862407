/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-use-before-define */
import React from 'react';
import {
  useOutletContext,
  useParams,
  useSubmit,
  Link,
  Outlet,
} from 'react-router-dom';
import * as Yup from 'yup';
import CreateForm from '../Forms/CreateForm/CreateForm';
import VendorImages from './VendorImages';
import FormWrapper from '../Forms/builders/FormWrapper';
import createFileForm from '../Forms/helpers/createFileForm';
import useFormikTemplate from '../Forms/hooks/useFormikTemplate';
import { formatPhoneNumber, phoneUS } from '../Forms/yup/customValidations';
import LBOffCanvas from '../UI/LBOffCanvas';
import useModal from '../UI/LBOffCanvas/useModal';

function CreateVendor() {
  const vendor = useOutletContext();
  const { show, size, handleCloseModal } = useModal(vendor ? 'update' : 'new');

  // FORM SCHENMA START
  const vendorForm = [
    {
      formGroup: 'Vendor information',
      groupChildren: [
        {
          name: 'vendor_name',
          label: 'First name',
          initialValue: vendor?.vendorFirst || '',
          type: Yup.string().required('This is a mandatory field'),
          required: true,
        },
        {
          name: 'vendor_last',
          label: 'Last Name',
          initialValue: vendor?.vendorLast || '',
          type: Yup.string(),
        },
        {
          name: 'email',
          label: 'Email',
          initialValue: vendor?.email || '',
          type: Yup.string().email().required('This is a mandatory field'),
          required: true,
        },
        {
          name: 'ein_ssn',
          label: 'EIN/SSN',
          initialValue: vendor?.einSnn || '',
          type: Yup.string(),
        },
        {
          name: 'mobile',
          label: 'Mobile number',
          formType: 'phone',
          initialValue: vendor?.mobile || '',
          type: phoneUS,
        },
        {
          name: 'phone',
          label: 'Phone',
          formType: 'phone',
          initialValue: vendor?.phone || '',
          type: phoneUS,
        },
        {
          name: 'vendor_trade',
          label: 'Trade',
          initialValue: Number(vendor?.trade) || '',
          formType: 'trades',
          type: Yup.string(),
          labelAction: <Link to="./trades">Manage</Link>,
        },
      ],
    },
    {
      formGroup: 'Aditional information',
      groupChildren: [
        {
          name: 'autocomplete_address',
          label: 'Search address',
          initialValue: '',
          type: Yup.string(),
          formType: 'autocompleteAddress',
          className: 'input-wide',
          fieldNames: {
            street: 'address_1',
            city: 'city',
            state: 'state',
            zipcode: 'zipcode',
            country: 'country',
          },
        },
        {
          name: 'address_1',
          label: 'Street address',
          initialValue: vendor?.address1 || '',
          type: Yup.string(),
          autocomplete: 'address-line1',
        },
        {
          name: 'address_2',
          label: 'Street address 2',
          initialValue: vendor?.address2 || '',
          type: Yup.string(),
        },
        {
          name: 'city',
          label: 'City',
          initialValue: vendor?.city || '',
          type: Yup.string(),
        },
        {
          name: 'state',
          label: 'State',
          initialValue: vendor?.state || '',
          type: Yup.string(),
        },
        {
          name: 'zipcode',
          label: 'Zip Code',
          initialValue: vendor?.zipCode || '',
          type: Yup.string(),
        },
        {
          name: 'country',
          label: 'Country',
          initialValue: vendor?.country || '',
          type: Yup.string(),
        },
        {
          name: 'notes',
          label: 'Notes',
          initialValue: vendor?.notes || '',
          type: Yup.string(),
          formType: 'textarea',
          className: 'input-wide',
        },
      ],
    },
  ];

  const images = [
    {
      name: 'vendor_photo',
      Yup: Yup.string(),
      initialValue: vendor?.photo || '',
      label: 'Photo',
      allowed: 'image',
      resize: { type: 'crop', x: 500, y: 500 },
    },
    {
      name: 'insurance',
      Yup: Yup.string(),
      initialValue: vendor?.insurance || '',
      label: 'Insurance',
      allowed: 'pdfAndImage',
      resize: { type: 'cropAndResize', x: 1020, y: 1320 },
    },
    {
      name: 'w_9',
      Yup: Yup.string(),
      initialValue: vendor?.w9 || '',
      label: 'W-9',
      allowed: 'pdfAndImage',
      resize: { type: 'cropAndResize', x: 1020, y: 1320 },
    },
  ];

  const insuranceExpires = [
    {
      name: 'insurance_expire_date',
      label: 'Insurance expire date',
      initialValue: vendor?.insuranceExpireDate || '',
      // make yup validation when insurance is uploaded
      type: Yup.string().when(['insurance'], {
        is: (insurance) => insurance,
        then: (schema) =>
          schema.required(
            'This is a mandatory field when insurance is uploaded'
          ),
      }),
      formType: 'date',
      required: true,
    },
  ];

  const formValues = [...vendorForm, ...images, ...insuranceExpires];
  const { vendorId } = useParams();
  const reactRouterSubmit = useSubmit();

  const submitAction = async (values) => {
    if (vendorId) {
      // Update action
      const nextValues = createFileForm(
        {
          ...values,
          mobile: formatPhoneNumber(values.mobile),
          phone: formatPhoneNumber(values.phone),
        },
        formData
      );
      nextValues.append('id', vendorId);
      reactRouterSubmit(nextValues, {
        method: 'POST',
        encType: 'multipart/form-data',
      });
    } else {
      // Create (new) action
      const nextValues = createFileForm(
        {
          ...values,
          mobile: formatPhoneNumber(values.mobile),
          phone: formatPhoneNumber(values.phone),
        },
        formData
      );
      reactRouterSubmit(nextValues, {
        method: 'POST',
        encType: 'multipart/form-data',
      });
    }
    formik.setSubmitting(false);
  };

  const [formik] = useFormikTemplate({
    initial: formValues,
    yupValues: formValues,
    submitAction,
  });

  // Tracks save the file sin this component cause formik is tracking them but no saving them
  const [formData, setFormData] = React.useState({});

  return (
    <>
      <FormWrapper
        id="CreateVendor"
        handleSubmit={formik.handleSubmit}
        isSubmitting={formik.isSubmitting}
        dirty={formik.dirty}
        isValid={formik.isValid}
        portalId="new-vendor-submit-container"
        label={vendor ? 'Save changes' : 'Create vendor'}
      >
        <CreateForm structure={vendorForm} {...formik} />
        <VendorImages
          fields={images}
          insuranceExpires={insuranceExpires}
          setFormData={setFormData}
          handleForms={formik}
        />
      </FormWrapper>
      <div style={{ position: 'sticky', bottom: 0, right: 0 }}>
        <div
          className="sticky__submit-container"
          id="new-vendor-submit-container"
        />
      </div>
      <LBOffCanvas show={show} size={size} handleCloseModal={handleCloseModal}>
        <Outlet
          context={{
            tradeId: formik.values.vendor_trade,
            setFieldValue: formik.setFieldValue,
            fieldKey: 'vendor_trade',
          }}
        />
      </LBOffCanvas>
    </>
  );
}

export default CreateVendor;

import React from 'react';
import { Link, useLoaderData, useLocation } from 'react-router-dom';
import PDFTesting from '../react-pdf/PDFTesting';
import { I_PDF_Document } from '../react-pdf/propTypes';
import logo from '../../../img/logo/logo_invoice_manager.svg';
import { capitalize } from '../../Helpers/stringFunctions';

import './PublicPdfPreview.scss';

function PublicPdfPreview() {
  const loaderData = useLoaderData() as I_PDF_Document;
  const { pathname } = useLocation();
  const type = pathname.substring(pathname.lastIndexOf('/') + 1);
  const companyLogo = loaderData?.data?.user?.company?.logo;

  console.log({ companyLogo });

  if (!loaderData)
    throw new Error("Couldn't load PDF data. Please try again later.");
  //  d-flex flex-column flex-md-row gap-3 text-md-left text-center justify-content-between align-items-center mb-2
  return (
    <main className="preview-pdf__container">
      <header className="preview-pdf__info">
        {companyLogo && (
          <img
            src={companyLogo}
            alt={`${loaderData.data.user.company.name} logo`}
          />
        )}

        <h1 className="fs-3 text-primary fw-medium mb-0">
          {loaderData.data.user.company.name}
        </h1>
        <h2 className="fs-5 text-secondary mb-2">
          Previewing {capitalize(type)}:{' '}
          <strong>{loaderData.data.number}</strong>
        </h2>

        <div id="download-pdf" />
      </header>
      <div
        className="preview-pdf__pdf"
        style={{
          aspectRatio: '8.5 / 11',
          height: 'auto',
          width: 'min(95vw, 692px)',
        }}
      >
        <PDFTesting
          data={loaderData.data}
          portalId="download-pdf"
          publicRoute
          overrideColors={loaderData.colors}
          overrideLayout={loaderData.layoutNumber}
        />

        <footer className="preview-pdf__footer d-flex gap-3 align-items-center justify-content-center">
          <span className="text-muted small">Powered by:</span>
          <Link to="/">
            <img style={{ height: 40 }} src={logo} alt="leadsbox logo" />
          </Link>
        </footer>
      </div>
    </main>
  );
}

export default PublicPdfPreview;

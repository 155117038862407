import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { I_Data } from '../propTypes';
import { PAYMENT_OPTIONS } from '../../../Payments/CreatePayment';

const s = StyleSheet.create({
  container: {
    width: '100%',
    padding: '130px 50px 20px 50px',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 9,
    fontWeight: 300,
  },
  label: {
    fontSize: 8,
    fontWeight: 600,
  },
  name: {
    fontSize: 18,
    fontWeight: 600,
  },
  checkbox: {
    height: 8,
    width: 8,
    border: '1px solid gray',
    borderRadius: 3,
  },
  checkLine: {
    width: 80,
    borderBottom: '1px solid gray',
    alignSelf: 'flex-end',
  },
  paymentInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
});

function BillingTo({ data }: { data: I_Data }) {
  const paymentMethodLabel = PAYMENT_OPTIONS.find(
    (opt) => opt.value === data.paymentMethod
  )?.label;

  return (
    <View style={s.container}>
      <View style={s.content}>
        <View style={{ ...s.col, paddingTop: 8 }}>
          <Text style={s.label}>Billing to:</Text>
        </View>
        <View
          style={{
            ...s.col,
            width: data.type === 'receipt' ? '60%' : '100%',
            marginLeft: 8,
          }}
        >
          {data.customer?.address?.street_address_1 ? (
            <>
              <Text style={s.name}>{data.customer.name}</Text>
              <Text>{data.customer.address.street_address_1}</Text>
              {data.customer.address?.street_address_2 && (
                <Text>{data.customer.address.street_address_2}</Text>
              )}
              <Text>
                {data.customer.address.city}, {data.customer.address.state}
              </Text>
              <Text>
                {data.customer.address.country}, {data.customer.address.zipcode}
              </Text>
            </>
          ) : (
            <Text style={s.name}>No address</Text>
          )}
        </View>
        {data.type === 'receipt' && (
          <View
            style={{
              ...s.col,
              width: '40%',
              justifyContent: 'center',
              gap: 6,
              marginLeft: 16,
              paddingLeft: 16,
              borderLeft: '1px solid gray',
            }}
          >
            <View>
              <Text
                style={{ marginBottom: 3, fontSize: 9, fontWeight: 'bold' }}
              >
                Payment Method:
              </Text>
              <View style={s.paymentInfoRow}>
                <Text>
                  {paymentMethodLabel}{' '}
                  {data.paymentMethod === 3
                    ? `nº ${data?.checkNumber || 'Missing Nº'}`
                    : null}
                </Text>
              </View>
            </View>

            <View>
              <Text
                style={{ marginBottom: 3, fontSize: 9, fontWeight: 'bold' }}
              >
                Invoice Nº:
              </Text>
              <View style={s.paymentInfoRow}>
                <Text>{data?.invoiceNumber || 'Missing invoice Nº'}</Text>
              </View>
            </View>
          </View>
        )}
      </View>
    </View>
  );
}

export default BillingTo;

/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { useLoaderData, useParams, useSubmit } from 'react-router-dom';
import { useFormik } from 'formik';
import { string, setLocale, object } from 'yup';
import CreateForm from '../components/Forms/CreateForm/CreateForm';
import FormWrapper from '../components/Forms/builders/FormWrapper';
import extractValues from '../components/Helpers/extractValues';
import {
  formatPhoneNumber,
  phoneUS,
} from '../components/Forms/yup/customValidations';

setLocale({
  mixed: {
    required: 'This is a mandatory field',
    default: 'Invalid input',
  },
  string: {
    email: 'Invalid e-mail format',
  },
});

function ContactFormPage() {
  // We still need to grab the token (probably as a qs as well)
  // and then send it to the action to check the validity
  // Another option could be adding it to a visaully hidden and disabled field

  // Get the QS data from the loader
  const { token, mobile } = useLoaderData();
  const { userId } = useParams();

  const contactForm = [
    {
      formGroup: 'Customer Information',
      groupChildren: [
        {
          name: 'last_name',
          label: 'First name',
          initialValues: '',
          type: string().required(),
          required: true,
        },
        {
          name: 'first_name',
          label: 'Last Name',
          initialValues: '',
          type: string().required(),
          required: true,
        },
        {
          name: 'email',
          label: 'Email',
          initialValues: '',
          type: string().email().required(),
          required: true,
        },
        {
          name: 'mobile_number',
          label: 'Mobile number',
          formType: 'phone',
          initialValues: mobile || '',
          type: phoneUS.required(),
          required: true,
        },
        {
          name: 'phone_number',
          label: 'Phone Number',
          formType: 'phone',
          initialValues: '',
          type: phoneUS,
        },
        {
          name: 'fax',
          label: 'Fax Number',
          formType: 'phone',
          initialValues: '',
          type: phoneUS,
        },
        {
          name: 'company',
          label: 'Company Name',
          initialValues: '',
          type: string(),
          className: 'input-wide',
        },
      ],
    },
    {
      formGroup: 'Billing Address',
      groupChildren: [
        {
          name: 'autocomplete_address',
          label: 'Search Address',
          initialValues: '',
          formType: 'autocompleteAddress',
          type: string(),
          fieldNames: {
            street: 'street_address_1',
            city: 'city',
            state: 'state',
            zipcode: 'zip',
            country: 'country',
          },
          required: false,
          className: 'input-wide',
        },
        {
          name: 'street_address_1',
          label: 'Street Address',
          initialValues: '',
          type: string().required(),
          required: true,
          autocomplete: 'address-line1',
        },
        {
          name: 'street_address_2',
          label: 'Street Address 2',
          initialValues: '',
          type: string(),
          required: false,
        },
        {
          name: 'city',
          label: 'City',
          initialValues: '',
          type: string().required(),
          required: true,
        },
        {
          name: 'state',
          label: 'State',
          initialValues: '',
          type: string().required(),
          required: true,
        },
        {
          name: 'zip',
          label: 'Zip Code',
          initialValues: '',
          type: string().required(),
          required: true,
        },
        {
          name: 'country',
          label: 'Country',
          initialValues: '',
          type: string().required(),
          required: true,
        },
      ],
    },
  ];

  const initialValues = extractValues('initialValues', [...contactForm]);

  const SchemaObject = extractValues('type', [...contactForm]);

  const formSchema = object().shape(SchemaObject);

  const reactRouterSubmit = useSubmit();

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      reactRouterSubmit(
        {
          ...values,
          customer_token: token,
          user_id: userId,
          fax: formatPhoneNumber(values.fax),
          phone_number: formatPhoneNumber(values.phone_number),
          mobile_number: formatPhoneNumber(values.mobile_number),
        },
        {
          method: 'post',
          action: `/${userId}/create-form`,
          encType: 'application/json',
        }
      );
    },
  });

  return (
    <>
      <FormWrapper
        id="new-customer-form-token"
        handleSubmit={formik.handleSubmit}
        isSubmitting={formik.isSubmitting}
        dirty={formik.dirty}
        isValid={formik.isValid}
        label="Create customer"
        portalId="contact-form-create-submit-container"
      >
        <CreateForm structure={contactForm} {...formik} />
      </FormWrapper>
      <div style={{ position: 'sticky', bottom: 0, right: 0 }}>
        <div
          className="sticky__submit-container"
          id="contact-form-create-submit-container"
        />
      </div>
    </>
  );
}

export default ContactFormPage;

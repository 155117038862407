/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  useLoaderData,
  useNavigate,
  useNavigation,
  useOutletContext,
  useRouteLoaderData,
  useSubmit,
} from 'react-router-dom';
import { string } from 'yup';
import { Form } from 'react-bootstrap';
import { formatPhoneNumber, phoneUS } from '../../Forms/yup/customValidations';
import useFormikTemplate from '../../Forms/hooks/useFormikTemplate';
import CreateForm from '../../Forms/CreateForm/index';
import ActionErrorInformation from '../../../API/ActionErrorInformation';
import FormSubmitPortal from '../../Forms/builders/FormSubmitPortal';
import IconButton from '../../UI/IconButton/IconButton.tsx';
import { Carat } from '../../UI/Icons/Icons';
import CustomerInfo from './CustomerInfo';

function CustomerForm({ useExistingCustomer }) {
  const {
    user: {
      onboarding_helpers: { customers_count: customerCount },
    },
  } = useRouteLoaderData('root');
  const { customers } = useLoaderData();

  const { customer, customerChangeHanlder } = useOutletContext();
  const navigate = useNavigate();

  const updateCustomer = (id) => {
    const [foundCustomer] = customers.filter((c) => c.id === id);
    return customerChangeHanlder({
      id: foundCustomer?.id || '',
      fullName: foundCustomer?.name || '',
      fullAddress: foundCustomer?.address?.full_address || '',
      email: foundCustomer?.email || '',
    });
  };

  const selectField = [
    {
      formGroup: 'Select customer',
      groupChildren: [
        {
          name: 'customer_id',
          label: 'Select a customer',
          initialValue: customer?.id || '',
          type: !useExistingCustomer
            ? string()
            : string().required('This field is mandatory'),
          formType: 'customer',
          change: (e) => updateCustomer(e),
          config: {
            address: null,
            email: null,
            name: null,
          },
          className: 'flex__span-12',
        },
      ],
    },
  ];

  const createFields = [
    {
      formGroup: 'Personal infomation',
      showTitle: false,
      groupChildren: [
        {
          name: 'first_name',
          label: 'First Name',
          initialValue: '',
          type: useExistingCustomer
            ? string()
            : string().required('This field is mandatory'),
          required: true,
          className: 'flex__span-6',
        },
        {
          name: 'last_name',
          label: 'Last Name',
          initialValue: '',
          type: string(),
          className: 'flex__span-6',
        },
        {
          name: 'email',
          label: 'Email',
          initialValue: '',
          type: useExistingCustomer
            ? string()
            : string().required('This field is mandatory'),
          formType: 'email',
          required: true,
          className: 'flex__span-12',
        },
        {
          name: 'mobile',
          label: 'Mobile',
          formType: 'phone',
          initialValue: '',
          type: useExistingCustomer
            ? string()
            : phoneUS.required('This field is mandatory'),
          required: true,
          className: 'flex__span-12',
        },
      ],
    },
    {
      formGroup: 'Address',
      groupChildren: [
        {
          name: 'autocomplete_address',
          label: 'Search address',
          className: 'flex__span-12',
          initialValue: '',
          type: string(),
          formType: 'autocompleteAddress',
        },
        {
          name: 'street_address',
          label: 'Street Address',
          initialValue: '',
          type: useExistingCustomer
            ? string()
            : string().required('This field is mandatory'),
          required: true,
          autocomplete: 'address-line1',
          className: 'flex__span-6',
        },
        {
          name: 'street_address_2',
          label: 'Street Address 2',
          initialValue: '',
          type: string(),
          autocomplete: 'address-line2',
          className: 'flex__span-6',
        },
        {
          name: 'city',
          label: 'City',
          initialValue: '',
          type: useExistingCustomer
            ? string()
            : string().required('This field is mandatory'),
          required: true,
          className: 'flex__span-6',
        },
        {
          name: 'state',
          label: 'State',
          initialValue: '',
          type: useExistingCustomer
            ? string()
            : string().required('This field is mandatory'),
          required: true,
          className: 'flex__span-6',
        },
        {
          name: 'zipcode',
          label: 'Zip code',
          initialValue: '',
          type: useExistingCustomer
            ? string()
            : string().required('This field is mandatory'),
          required: true,
          className: 'flex__span-6',
        },
        {
          name: 'country',
          label: 'Country',
          type: useExistingCustomer
            ? string()
            : string().required('This field is mandatory'),
          required: true,
          initialValue: '',
          className: 'flex__span-6',
        },
      ],
    },
  ];

  const formValues = [...selectField, ...createFields];

  const submit = useSubmit();
  const submitAction = (values) => {
    if (customer?.id) {
      return navigate('../prod-and-serv');
    }

    const nextValues = {
      ...values,
      mobile: formatPhoneNumber(values.mobile),
    };
    return submit(nextValues, { method: 'post' });
  };

  // Initial values
  const [formik] = useFormikTemplate({
    initial: formValues,
    yupValues: formValues,
    submitAction,
    enableReinitialize: true,
  });

  React.useEffect(() => {
    formik.validateForm();
  }, [customer.id]);

  React.useEffect(() => {
    async function updateField(field, value) {
      await formik.setFieldValue(field, value);
    }

    if (!useExistingCustomer) {
      updateField('customer_id', '');
      customerChangeHanlder({
        id: '',
        fullAddress: '',
        email: '',
        fullName: '',
      });
    }
    updateField('customer_id', customer.id);
  }, [useExistingCustomer]);

  const navigation = useNavigation();
  const submitting = navigation.state !== 'idle' || formik.isSubmitting;

  return (
    <div className="d-flex flex-column">
      <ActionErrorInformation />
      <Form
        className="mt-2 flex__form-wrapper px-0"
        noValidate
        onSubmit={formik.handleSubmit}
        id="ob-new-customer"
        method="post"
      >
        {customerCount > 0 && useExistingCustomer && (
          <>
            <CreateForm structure={selectField} {...formik} />
            {customer && <CustomerInfo customer={customer} />}
          </>
        )}
        {!useExistingCustomer && (
          <CreateForm structure={createFields} {...formik} />
        )}

        <FormSubmitPortal portalId="ob-customer-submit">
          <IconButton
            text="Next"
            type="submit"
            form="ob-new-customer"
            alignText="left"
            color="primary"
            outline={false}
            disabled={submitting || (!formik.isValid && !formik.dirty)}
          >
            <Carat />
          </IconButton>
        </FormSubmitPortal>
      </Form>
    </div>
  );
}

CustomerForm.propTypes = {
  useExistingCustomer: PropTypes.bool,
};

export default CustomerForm;

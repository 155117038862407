import React from 'react';
import { usePDF } from '@react-pdf/renderer';
import { Button, Spinner } from 'react-bootstrap';
import { useFetcher } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { createPortal } from 'react-dom';
import PDFDocument from './Document';
import PDFViewer from '../../Checks/PDF/lbPDFViewer';
import IconButton from '../../UI/IconButton/IconButton';

import type { I_Colors, I_Data } from './propTypes';
import uploadPdf from './uploadPdf';
import { Print } from '../../UI/Icons/Icons';

interface I_PDFTesting {
  data: I_Data;
  overrideLayout?: number;
  overrideColors?: I_Colors;
  save?: boolean;
  printDocument?: boolean;
  portalId?: string;
  publicRoute?: boolean;
}

function PDFTesting({
  data,
  overrideLayout,
  overrideColors,
  save,
  printDocument,
  portalId,
  publicRoute = false,
}: I_PDFTesting) {
  // Get the template config from the user saved settings
  const templateFetcher = useFetcher();
  const [fileUploaded, setFileUploaded] = React.useState(false);

  // Runs only once
  React.useEffect(() => {
    if (publicRoute) return;
    if (templateFetcher.state === 'idle' && !templateFetcher.data) {
      templateFetcher.load('/settings/templates');
    }
  }, []);

  const { data: fetcherData } = templateFetcher?.data || {};

  // Build color object for the PDF
  const colors = overrideColors || {
    color1: fetcherData?.primary_color_bg || 'var(--bs-gray-700)',
    color2: fetcherData?.secondary_color_bg || 'var(--bs-gray-700)',
    color3: fetcherData?.third_color_bg || 'var(--bs-gray-700)',
    color4: 'var(--bs-teal)',
    color1text: fetcherData?.primary_color_text || 'var(--bs-gray-700)',
    color2text: fetcherData?.secondary_color_text || 'var(--bs-gray-700)',
    color3text: fetcherData?.third_color_text || 'var(--bs-gray-700)',
    color4text: 'var(--bs-white)',
    bodyText: 'var(--bs-gray-900)',
  };

  // Determine layout
  const layoutNumber = overrideLayout || Number(fetcherData?.invoice_type);

  // Create PDF instance
  const [instance, updateInstance] = usePDF({
    document: (
      <PDFDocument data={data} colors={colors} layoutNumber={layoutNumber} />
    ),
  });

  // Create a debounced version of updateInstance using useCallback to memoize it
  const debouncedUpdateInstance = React.useCallback(
    debounce((component) => {
      updateInstance(component);
    }, 500),
    [updateInstance]
  );

  // iFrame Ref
  const iFrameRef = React.useRef<HTMLIFrameElement>(null);

  // Print handler
  const handlePrint = () => {
    if (!iFrameRef.current) return;
    if (instance.loading) return;
    iFrameRef.current.focus();
    iFrameRef.current.contentWindow?.print();
  };

  // Upload file (for sending)
  const uploadFileFetcher = useFetcher();

  React.useEffect(() => {
    if (save && !fileUploaded && fetcherData) {
      const formData = uploadPdf({ data, instance });
      if (!formData) return;
      setFileUploaded(true);
      uploadFileFetcher.submit(formData, {
        method: 'POST',
        encType: 'multipart/form-data',
      });
    }
  }, [save, instance, fetcherData]);

  // Update PDF preview (debounced)
  React.useEffect(() => {
    debouncedUpdateInstance(
      <PDFDocument data={data} colors={colors} layoutNumber={layoutNumber} />
    );
  }, [fetcherData, data]);

  // Check if container is in DOM
  const container = portalId && document.getElementById(portalId);

  // Render as print button (estimate -> manage document modal)
  if (printDocument) {
    return (
      <>
        <div className="d-none">
          <PDFViewer
            title="Document Preview (Hidden)"
            innerRef={iFrameRef}
            instance={instance}
            className={null}
          />
        </div>
        <IconButton
          className="icon-button__modal"
          size={22}
          strokeWidth={3}
          text={!layoutNumber || !data ? 'Loading...' : 'Print'}
          alignText="left"
          color="primary"
          outline={false}
          onClick={handlePrint}
          disabled={!layoutNumber || !data}
        >
          <Print />
        </IconButton>
      </>
    );
  }

  return (
    <div>
      {portalId &&
        !printDocument &&
        container &&
        createPortal(
          <div className="d-flex gap-2">
            <Button
              as="a"
              href={instance.url || '#'}
              download={`${data.type}-${data.number}-${new Date().toLocaleDateString('en-US', { dateStyle: 'short' }).replaceAll('/', '-')}`}
              target="_blank"
              disabled={Boolean(instance.error) || instance.loading}
            >
              Download
            </Button>
            <Button onClick={handlePrint}>Print</Button>
          </div>,
          container
        )}
      <div style={{ width: '100%', height: 'auto', aspectRatio: '8.5 / 11' }}>
        {!layoutNumber || !data ? (
          <div
            className="w-100 h-100 d-flex justify-content-center align-items-center rounded"
            style={{ backgroundColor: 'var(--bs-gray-100)' }}
          >
            <Spinner />
          </div>
        ) : (
          <PDFViewer
            title="Document Preview" // Not sure why this is required?
            innerRef={iFrameRef}
            instance={instance}
            className=""
          />
        )}
      </div>
    </div>
  );
}

export default React.memo(PDFTesting);

import paymentsAPI from '../../API/routesAPI/paymentsAPI';
import { handleResposeAbstraction } from '../../API/fetchUtils';

// handle payment response
export const parsePayment = (api) => ({
  payment: {
    id: api.id,
    date: api.payment_date,
    method_id: api.payment_method_id,
    method: api.payment_method,
    check: api.check_number,
    amount: api.amount,
    number: api.payment_number,
    statement_notes: api.statement_notes,
    private_notes: api.private_notes,
  },
  customer: {
    id: api.customer_id,
    name: api.customer_name,
    email: api.customer_email,
  },
  invoice: {
    id: api.invoice_id,
    number: api.invoice,
    date: api.invoice_date,
    amount: {
      total: api.amount,
      tax: api.invoice_amount.invoice_tax_total,
      paid: api.invoice_amount.invoice_paid,
      balance: api.invoice_amount.invoice_balance,
      subtotal: api.invoice_amount.invoice_total,
    },
  },
});

const nextPayments = (api) => ({
  message: api.message,
  payments: api?.payments
    ? api.payments.map((payment) => ({
        payment: {
          id: payment.id,
          date: payment.payment_date,
          method: payment.payment_method,
          amount: payment.amount,
        },
        invoice: {
          number: payment.invoice,
          balance: payment.balance,
          id: payment.invoice_id,
        },
        customer: {
          id: payment.customer_id,
          name: payment.customer_name,
        },
      }))
    : [],
});

export const paymentsLoader = async ({ params }) => {
  const { customerId } = params;
  const response = await paymentsAPI.getAll(customerId);
  let result = await response.json();
  if (result?.payments) {
    result = nextPayments(result);
  }
  return handleResposeAbstraction({ response, result });
};

export const createPaymentAction = async ({ request }) => {
  const body = await request.formData();
  const paymentMethod = body.get('payment_method_id');
  if (paymentMethod === '2') {
    const response = await paymentsAPI.stripeMethod(body);
    const result = await response.json();
    const redirectTo = result?.url ? result?.url : false;
    return handleResposeAbstraction({
      response,
      result,
      redirectTo,
    });
  }
  const response = await paymentsAPI.create(body);
  const result = await response.json();
  const redirectTo = result?.id ? `../${result?.id}` : false;
  return handleResposeAbstraction({
    response,
    result,
    redirectTo,
  });
};

export const updatePaymentAction = async ({ request, params }) => {
  const body = await request.formData();
  const { paymentId } = params;
  body.append('id', paymentId);
  const response = await paymentsAPI.update(body);
  const result = await response.json();
  const redirectTo = result?.id ? `..` : false;
  return handleResposeAbstraction({
    response,
    result,
    redirectTo,
  });
};

export const paymentLoader = async ({ params }) => {
  const { paymentId } = params;
  const response = await paymentsAPI.get(paymentId);
  const result = await response.json();
  if (result?.data) {
    result.data = parsePayment(result.data);
  }
  return handleResposeAbstraction({ response, result });
};

export const deletePaymentAction = async ({ params }) => {
  const { paymentId } = params;
  const response = await paymentsAPI.delete(paymentId);

  return handleResposeAbstraction({
    response,
    redirectTo: '../..',
    showSuccess: true,
  });
};

export const nextPaymentNumberLoader = async () => {
  const response = await paymentsAPI.nextNumber();
  return handleResposeAbstraction({
    response,
  });
};

export const updateBusinessUrl = async ({ request }) => {
  const body = await request.formData();
  const redirectTo = body.get('redirectTo');
  const response = await paymentsAPI.updateBusinessUrl(body);
  return handleResposeAbstraction({
    response,
    redirectTo,
  });
};

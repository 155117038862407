/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */

import React, { CSSProperties, HTMLAttributes } from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import { Info } from '../UI/Icons/Icons';

import './HelpTooltip.scss';

interface I_HelpTooltip extends HTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  size: 'sm' | 'md' | 'lg';
  showTitle?: boolean;
}

const sizes = {
  sm: {
    triggerSize: '18px',
    iconSize: '10px',
  },
  md: {
    triggerSize: '24px',
    iconSize: '14px',
  },
  lg: {
    triggerSize: '30px',
    iconSize: '18px',
  },
};

function HelpTooltip({
  children,
  size = 'md',
  showTitle = true,
  ...delegated
}: I_HelpTooltip) {
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <Tooltip.Root open={open} onOpenChange={(e) => setOpen(e)}>
      <Tooltip.Trigger onClick={() => setOpen((prev) => !prev)} asChild>
        <button
          type="button"
          className="help-tooltip__icon"
          {...delegated}
          style={
            {
              ...delegated.style,
              '--ht-trigger-size': sizes[size].triggerSize,
              border: '1px solid rgba(var(--bs-info-rgb), 0.35)',
              boxShadow: open
                ? '0 0 0 0.25rem rgba(var(--bs-info-rgb), 0.20)'
                : 'none',
            } as CSSProperties
          }
        >
          <svg
            height={sizes[size].iconSize}
            width={sizes[size].iconSize}
            fill="rgba(var(--bs-info-rgb), 0.75)"
            stroke="transparent"
            strokeWidth={0}
            strokeLinecap="round"
            strokeLinejoin="round"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
          >
            <Info />
          </svg>
        </button>
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content
          className="help-tooltip__content"
          sideOffset={5}
          arrowPadding={32}
          collisionPadding={32}
          sticky="always"
        >
          {showTitle && (
            <h6 style={{ color: 'var(--bs-info)', fontSize: '1rem' }}>
              Information
            </h6>
          )}

          {children}
          <Tooltip.Arrow className="help-tooltip__arrow" />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  );
}

export default HelpTooltip;

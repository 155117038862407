import React from 'react';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { parseISO } from 'date-fns';
import Background from './Background';
import BillingTo from './BillingTo';

import { I_Colors, I_Data } from '../propTypes';

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  infoContainer: {
    color: 'white',
    position: 'absolute',
    top: 28,
    left: 462,
    width: 100,
    fontSize: 8,
    fontWeight: 600,
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  type: {
    fontSize: 18,
    marginBottom: 4,
  },
  companyContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    top: 31,
    left: 50,
    width: 300,
    height: 42,
  },
  companyLogo: {
    height: 42,
    width: 42,
    overflow: 'hidden',
    borderRadius: 4,
  },
  companyCol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 4,
  },
  companyName: {
    fontSize: 14,
    fontWeight: 600,
  },
  companySlogan: {
    fontSize: 9,
  },
});

const typeEnum = {
  invoice: 'Invoice',
  estimate: 'Estimate',
  receipt: 'Receipt',
};

function Header({ data, colors }: { data: I_Data; colors: I_Colors }) {
  return (
    <View style={styles.container}>
      <Background colors={colors} />
      <View style={styles.companyContainer}>
        {data.user.company?.logo ? (
          <View style={{ ...styles.companyCol, color: colors.color1text }}>
            <Image style={styles.companyLogo} src={data.user.company.logo} />
          </View>
        ) : null}

        <View style={{ ...styles.companyCol, color: colors.color1text }}>
          <Text style={styles.companyName}>{data.user.company.name}</Text>
          {data.user.company?.slogan ? (
            <Text style={styles.companySlogan}>{data.user.company.slogan}</Text>
          ) : null}
        </View>
      </View>
      <View style={{ ...styles.infoContainer, color: colors.color3text }}>
        <Text style={styles.type}>{typeEnum[data.type]}</Text>
        <View style={styles.infoRow}>
          <Text>Number</Text>
          <Text>{data.number}</Text>
        </View>
        <View style={styles.infoRow}>
          <Text>Date</Text>
          <Text>
            {parseISO(data.dates.dateCreated).toLocaleDateString('en-US', {
              year: 'numeric',
              day: '2-digit',
              month: '2-digit',
            })}
          </Text>
        </View>
        {data.type !== 'receipt' ? (
          <View style={styles.infoRow}>
            <Text>{data.type === 'invoice' ? 'Due date' : 'Expires'}</Text>
            <Text>
              {parseISO(data.dates.dateDue).toLocaleDateString('en-US', {
                year: 'numeric',
                day: '2-digit',
                month: '2-digit',
              })}
            </Text>
          </View>
        ) : null}
      </View>

      <BillingTo data={data} />
    </View>
  );
}

export default Header;
